import React from 'react'
import {
  useTable, usePagination
} from 'react-table'
import { KTSVG } from '../../../../_metronic/helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faObjectGroup } from '@fortawesome/free-solid-svg-icons'
import { Pagination } from '../table/Pagination'
import Select from 'react-select'
import { customStyles } from '../Styles/ReactSelect'

const QSVerifyTable = ({
  columns,
  data,
  fetchData,
  loading,
  pageCount: controlledPageCount,
  addGroup,
  setGroup,
  setDataset,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable({
    columns,
    data,
    initialState: { pageIndex: 0 },
    manualPagination: true,
    pageCount: controlledPageCount,
  },
    usePagination
  );

  const [isSearchable, setIsSearchable] = React.useState(false);

  const [filter, setFilter] = React.useState('');
  const [status, setStatus] = React.useState('all');
  const [groupby, setGroupby] = React.useState('group');

  const statusOption = [
    { value: 'all', label: 'all' },
    { value: 'submit', label: 'submit' },
    { value: 'processing', label: 'processing' },
  ]

  const handleFilter = (value) => {
    gotoPage(0);
    setFilter(value);
  }

  const resetStatus = () => {
    setStatus('all');
  }

  React.useEffect(() => {
    fetchData({ pageIndex, pageSize, filter, status, groupby })
  }, [fetchData, pageIndex, pageSize, filter, status, groupby])

  const getColumnLength = () => {
    let column_length = 0;
    for (let i = 0; i < columns.length; i++) {
      column_length = column_length + columns[i].columns.length;
    }
    return column_length;
  }

  return (
    <>
      <div className='card'>
        <div className='card-header'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input id='search' type='text' data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search'
                onInput={(e) => {
                  handleFilter(e.target.value)
                }} />
            </div>
          </div>
          <div className='card-toolbar'>
            {addGroup}
            <button
              className='btn btn-light-success me-2'
              onClick={() => {
                setGroup()
              }}
            >
              <FontAwesomeIcon icon={faObjectGroup} className='fa-fw' />
              &nbsp;Group
            </button>
            {/* <div>|</div>
            <button
              type='button'
              className='btn btn-light-primary me-3 mx-2'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
            >
              <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
              Filter
            </button>

            <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
              <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bold'>Filter Options</div>
              </div>

              <div className='separator border-gray-200'></div>

              <div className='px-7 py-5'>
                <div className='mb-10'>
                  <label>Status</label>
                  <Select
                    options={statusOption}
                    isSearchable={isSearchable}
                    onChange={(option) => {
                      setStatus(option.value)
                    }}
                    value={statusOption ? statusOption.find((option) => option.value == status) : ''}
                    styles={customStyles}
                  />
                </div>

                <div className='d-flex justify-content-end'>
                  <button
                    type='button'
                    className='btn btn-danger fw-semibold px-6'
                    data-kt-menu-dismiss='true'
                    data-kt-user-table-filter='reset'
                    onClick={resetStatus}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className='card-body'>
          <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
              <button className="nav-link active fw-bold" id="nav-group-tab" data-bs-toggle="tab" data-bs-target="#nav-group" type="button" role="tab" aria-controls="nav-group" aria-selected="true"
                onClick={() => {
                  setGroupby('group');
                  gotoPage(0);
                  setDataset([]);
                }}>
                Group
              </button>
              <button className="nav-link fw-bold" id="nav-grouped-tab" data-bs-toggle="tab" data-bs-target="#nav-grouped" type="button" role="tab" aria-controls="nav-grouped" aria-selected="false"
                onClick={() => { 
                  setGroupby('grouped');
                  gotoPage(0);
                  setDataset([]);
                }}>
                Grouped
              </button>
            </div>
          </nav>
          <div className="tab-content" id="nav-tabContent">
            <div className="tab-pane fade show active" id="nav-group" role="tabpanel" aria-labelledby="nav-group-tab">
              <div className='table-responsive'>
                <table {...getTableProps()} className='table table-sm table-hover table-row-bordered align-middle gy-4 gs-9 text-center'>
                  <thead className='border-bottom border-gray-200 fs-6 text-gray-600 fw-bold bg-light bg-opacity-75'>
                    {headerGroups.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps} style={{ borderBottom: 'none' }}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps([
                              {
                                className: column.headerClassName,
                              },
                            ])}
                          >
                            {column.render('Header')}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()} className='fw-semibold text-gray-600'>
                    {page.length > 0 ? (
                      <>
                        {page.map((row, i) => {
                          prepareRow(row)
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                              })}
                            </tr>
                          )
                        })}
                        <tr>
                          {loading ? (
                            <td colSpan={columns.length}>Loading...</td>
                          ) : (
                            ''
                          )}
                        </tr>
                      </>
                    ) : (
                      <tr>
                        <td colSpan={getColumnLength()}>
                          <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                            No matching records found
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination pageOptions={pageOptions}
                pageIndex={pageIndex}
                gotoPage={gotoPage}
                previousPage={previousPage}
                nextPage={nextPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                pageCount={pageCount} />
            </div>
            <div className="tab-pane fade" id="nav-grouped" role="tabpanel" aria-labelledby="nav-grouped-tab">
              <div className='table-responsive'>
                <table {...getTableProps()} className='table table-sm table-hover table-row-bordered align-middle gy-4 gs-9 text-center'>
                  <thead className='border-bottom border-gray-200 fs-6 text-gray-600 fw-bold bg-light bg-opacity-75'>
                    {headerGroups.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps} style={{ borderBottom: 'none' }}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps([
                              {
                                className: column.headerClassName,
                              },
                            ])}
                          >
                            {column.render('Header')}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()} className='fw-semibold text-gray-600'>
                    {page.length > 0 ? (
                      <>
                        {page.map((row, i) => {
                          prepareRow(row)
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                              })}
                            </tr>
                          )
                        })}
                        <tr>
                          {loading ? (
                            <td colSpan={columns.length}>Loading...</td>
                          ) : (
                            ''
                          )}
                        </tr>
                      </>
                    ) : (
                      <tr>
                        <td colSpan={getColumnLength()}>
                          <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                            No matching records found
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination pageOptions={pageOptions}
                pageIndex={pageIndex}
                gotoPage={gotoPage}
                previousPage={previousPage}
                nextPage={nextPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                pageCount={pageCount} />
            </div>
          </div>
        </div>
      </div >
    </>
  )
}

export { QSVerifyTable }
