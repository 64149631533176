import {useState, useEffect} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import {Modal, Button, Form, ModalBody} from 'react-bootstrap'
import Swal from 'sweetalert2'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {customStyles} from '../../Styles/ReactSelect'
import {SUPFind, SUPSave, SUPUpdate} from '../../../api/requests/SupplierRequest'
import {SGOption} from '../../../api/requests/SupplierGroupRequest'
import {BranchOption} from '../../../api/requests/BranchRequest'
import {QSNewSupplier} from '../../../api/requests/QuotationRequest'
import Select from 'react-select'

const NewSupplier = ({data, clearData}: any) => {
  const obj = {
    id: 0,
    title: '',
    tel: '',
    email: '',
    fax: '',
    contact_name: '',
    address: '',
    active: 0,
    bpcs_code: '',
    branch_id: 0,
    supplier_group_id: 0,
  }

  const [show, setShow] = useState(false)
  const [supplier, setSupplier] = useState(obj)
  const [checked, setChecked] = useState(false)
  const [groups, setGroup] = useState([])
  const [branchs, setBranch] = useState([])

  const qsid: any = data.id || null

  const handleClose = () => {
    setSupplier(obj)
    setGroup(() => [])
    setBranch(() => [])
    clearData()
    setShow(false)
  }

  const handleShow = () => {
    getSupplier()
    getGroup()
    getBranch()
    setShow(true)
  }

  const setActive = async (ac: any) => {
    if (ac == true) {
      formik.setFieldValue('active', 1)
    } else if (ac == false) {
      formik.setFieldValue('active', 0)
    }
    setChecked(!checked)
  }

  const getSupplier = async () => {
    const id = data.supplier_id
    if (id != null) {
      const res: any = await SUPFind(id)
      setSupplier(res.data.data)
      if (res.data.data.active === 1) {
        setChecked(true)
      } else {
        setChecked(false)
      }
    } else if (data.supplier_other != null) {
      setSupplier({...supplier, title: data.supplier_other})
    }
  }

  const getGroup = async () => {
    let options: any = []
    const res: any = await SGOption()
    if (res.data.data.length > 0) {
      res.data.data.map((val: any) => {
        options.push({value: val.id, label: val.title})
      })
      setGroup(options)
    }
  }

  const getBranch = async () => {
    let options: any = []
    const res: any = await BranchOption()
    if (res.data.data.length > 0) {
      res.data.data.map((val: any) => {
        options.push({value: val.id, label: val.title})
      })
      setBranch(options)
    }
  }

  const IsEmty = () => {
    if (
      !formik.values.title ||
      !formik.values.tel ||
      !formik.values.email ||
      !formik.values.fax ||
      !formik.values.address ||
      !formik.values.bpcs_code ||
      !formik.values.branch_id ||
      !formik.values.supplier_group_id ||
      !formik.values.contact_name
    ) {
      Swal.fire({
        title: 'Please complete the information.',
        icon: 'error',
        confirmButtonColor: '#3085d6',
        heightAuto: false,
        returnFocus: false,
      })
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: supplier.id,
      title: supplier.title,
      tel: supplier.tel,
      email: supplier.email,
      fax: supplier.fax,
      contact_name: supplier.contact_name,
      address: supplier.address,
      active: supplier.active,
      bpcs_code: supplier.bpcs_code,
      branch_id: supplier.branch_id,
      supplier_group_id: supplier.supplier_group_id,
    },
    validationSchema: Yup.object({
      title: Yup.string().max(255, 'Must be 255 characters or less').required('title is required'),
      tel: Yup.string().max(50, 'Must be 50 characters or less').required('tel is required'),
      address: Yup.string().required('address is required'),
      email: Yup.string()
        .email('invalid email format')
        .max(255, 'Must be 255 characters or lesss')
        .required('email is required'),
      fax: Yup.string().max(255, 'Must be 255 characters or less').required('fax is required'),
      contact_name: Yup.string()
        .max(255, 'Must be 255 characters or less')
        .required('contact name is required'),
      active: Yup.number().required('active is required'),
      bpcs_code: Yup.string()
        .max(255, 'Must be 255 characters or less')
        .required('bpcs code is required'),
      branch_id: Yup.number().min(1, 'branch is required').required('branch is required'),
      supplier_group_id: Yup.number()
        .min(1, 'supplier group is required')
        .required('supplier group is required'),
    }),
    onSubmit: async (values) => {
      Swal.fire({
        title: 'Are you confirm to update?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        heightAuto: false,
        returnFocus: false,
        showLoaderOnConfirm: true,
        preConfirm: (e) => {
          return new Promise(async function (resolve) {
            if (qsid != null) {
              await QSNewSupplier(
                qsid,
                values.id,
                values.title,
                values.tel,
                values.email,
                values.fax,
                values.contact_name,
                values.address,
                values.active,
                values.bpcs_code,
                values.branch_id,
                values.supplier_group_id
              )
                .then((res) => {
                  if (res.data.status == true) {
                    Swal.fire({
                      title: 'Successful save!',
                      icon: 'success',
                      showConfirmButton: false,
                      heightAuto: false,
                      timer: 1500,
                      returnFocus: false,
                    }).then(() => {
                      handleClose()
                    })
                  }
                })
                .catch((err) => {
                  Swal.fire({
                    title: 'Failed to save',
                    icon: 'error',
                    heightAuto: false,
                    returnFocus: false,
                  })
                })
            }
          })
        },
      })
    },
  })

  return (
    <>
      <button className='btn btn-light-info btn-sm' onClick={handleShow}>
        <FontAwesomeIcon icon={faPlus} className='fa-fw' />
      </button>
      <Modal show={show} onHide={handleClose} className='modal-xl' centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {data.supplier_other != null ? 'New Supplier' : 'Edit Supplier'}
          </Modal.Title>
        </Modal.Header>
        <ModalBody>
          <Form onSubmit={formik.handleSubmit}>
            <div className='row'>
              <div className='col-md-12'>
                {/* title */}
                <div className='form-group mb-4'>
                  <label htmlFor='title' className='fw-bold required mb-2'>
                    Title
                  </label>
                  <input
                    type='text'
                    id='title'
                    name='title'
                    className={'form-control form-control-sm'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.title}
                  />
                  {formik.touched.title && formik.errors.title ? (
                    <div className='text-danger'>{formik.errors.title}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group mb-4'>
                  <label htmlFor='title' className='fw-bold required mb-2'>
                    Supplier Group
                  </label>
                  <Select
                    placeholder={'Please Select Supplier Group'}
                    options={groups}
                    value={
                      groups
                        ? groups.find(
                            (option: any) => option.value === formik.values.supplier_group_id
                          )
                        : ''
                    }
                    onChange={(option: any) =>
                      formik.setFieldValue('supplier_group_id', option.value)
                    }
                    styles={customStyles}
                  />
                  {formik.touched.supplier_group_id && formik.errors.supplier_group_id ? (
                    <div className='text-danger'>{formik.errors.supplier_group_id}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group mb-4'>
                  <label htmlFor='title' className='fw-bold required mb-2'>
                    Branch
                  </label>
                  <Select
                    placeholder={'Please Select Branch'}
                    options={branchs}
                    value={
                      branchs
                        ? branchs.find((option: any) => option.value === formik.values.branch_id)
                        : ''
                    }
                    onChange={(option: any) => formik.setFieldValue('branch_id', option.value)}
                    styles={customStyles}
                  />
                  {formik.touched.branch_id && formik.errors.branch_id ? (
                    <div className='text-danger'>{formik.errors.branch_id}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-4'>
                {/* tel */}
                <div className='form-group mb-4'>
                  <label htmlFor='title' className='fw-bold required mb-2'>
                    Tel
                  </label>
                  <input
                    type='text'
                    id='tel'
                    name='tel'
                    className={'form-control form-control-sm'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.tel}
                  />
                  {formik.touched.tel && formik.errors.tel ? (
                    <div className='text-danger'>{formik.errors.tel}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-4'>
                {/* email */}
                <div className='form-group mb-4'>
                  <label htmlFor='email' className='fw-bold required mb-2'>
                    Email
                  </label>
                  <input
                    type='text'
                    id='email'
                    name='email'
                    className={'form-control form-control-sm'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className='text-danger'>{formik.errors.email}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-4'>
                {/* Fax */}
                <div className='form-group mb-4'>
                  <label htmlFor='fax' className='fw-bold required mb-2'>
                    Fax
                  </label>
                  <input
                    type='text'
                    id='fax'
                    name='fax'
                    className={'form-control form-control-sm'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.fax}
                  />
                  {formik.touched.fax && formik.errors.fax ? (
                    <div className='text-danger'>{formik.errors.fax}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-4'>
                {/* Contact Name */}
                <div className='form-group mb-4'>
                  <label htmlFor='contact_name' className='fw-bold required mb-2'>
                    Contact Name
                  </label>
                  <input
                    type='text'
                    id='contact_name'
                    name='contact_name'
                    className={'form-control form-control-sm'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.contact_name}
                  />
                  {formik.touched.contact_name && formik.errors.contact_name ? (
                    <div className='text-danger'>{formik.errors.contact_name}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-4'>
                {/* BPCS CODE */}
                <div className='form-group mb-4'>
                  <label htmlFor='bpcs_code' className='fw-bold required mb-2'>
                    BPCS Code
                  </label>
                  <input
                    type='text'
                    id='bpcs_code'
                    name='bpcs_code'
                    className={'form-control form-control-sm'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.bpcs_code}
                  />
                  {formik.touched.bpcs_code && formik.errors.bpcs_code ? (
                    <div className='text-danger'>{formik.errors.bpcs_code}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-4'>
                <div className='form-group mb-4'>
                  <label htmlFor='active' className='fw-bold mb-2'>
                    Active
                  </label>
                  <div className='mt-2'>
                    <div className='form-check form-switch'>
                      <input
                        className='form-check-input'
                        checked={checked}
                        type='checkbox'
                        id='active'
                        name='active'
                        value={formik.values.active}
                        onChange={(e) => {
                          setActive(e.target.checked)
                        }}
                      />
                    </div>
                  </div>
                  {formik.touched.active && formik.errors.active ? (
                    <div className='text-danger'>{formik.errors.active}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-12'>
                {/* address */}
                <div className='form-group mb-4'>
                  <label htmlFor='title' className='fw-bold mb-2'>
                    Address <span className='text-danger'>*</span>
                  </label>
                  <textarea
                    name='address'
                    id='address'
                    className={'form-control form-control-sm'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.address}
                  ></textarea>
                  {formik.touched.address && formik.errors.address ? (
                    <div className='text-danger'>{formik.errors.address}</div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className='text-end mt-4'>
              <Button variant='primary' type='submit' className='me-2' onClick={IsEmty}>
                Save
              </Button>
              <Button variant='secondary' onClick={handleClose}>
                Close
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}

export {NewSupplier}
