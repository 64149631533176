import {useState, useRef} from 'react'
import {Modal, Button} from 'react-bootstrap'
import {KTSVG} from '../../../../../_metronic/helpers'
import {activeFormat} from '../../../../modules/widgets/func/ActiveFormat'

const SupShow = ({data}: any) => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => {
    formButtonRef.current.blur()
    setShow(true)
  }
  const formButtonRef: any = useRef(null)

  return (
    <>
      <button
        ref={formButtonRef}
        className='btn btn-icon btn-light-info  btn-sm me-2'
        onClick={handleShow}
      >
        <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-3' />
      </button>
      <Modal show={show} onHide={handleClose} className='modal-xl' centered>
        <Modal.Header closeButton>
          <Modal.Title>Supplier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='form-group mb-2'>
            <label htmlFor='' className='fw-bold fs-6 mb-2'>
              Title
            </label>
            <input
              type='text'
              className='form-control form-control-solid'
              value={data.title || '-'}
              readOnly
            />
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <div className='form-group mb-2'>
                <label htmlFor='' className='fw-bold fs-6 mb-2'>
                  Supplier Group
                </label>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  value={data.supplier_group_title || '-'}
                  readOnly
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group mb-2'>
                <label htmlFor='' className='fw-bold fs-6 mb-2'>
                  Branch
                </label>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  value={data.branch_title || '-'}
                  readOnly
                />
              </div>
            </div>
            <div className='col-md-4'>
              <div className='form-group mb-2'>
                <label htmlFor='' className='fw-bold fs-6 mb-2'>
                  Tel
                </label>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  value={data.tel || '-'}
                  readOnly
                />
              </div>
            </div>
            <div className='col-md-4'>
              <div className='form-group mb-2'>
                <label htmlFor='' className='fw-bold fs-6 mb-2'>
                  Email
                </label>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  value={data.email || '-'}
                  readOnly
                />
              </div>
            </div>
            <div className='col-md-4'>
              <div className='form-group mb-2'>
                <label htmlFor='' className='fw-bold fs-6 mb-2'>
                  Fax
                </label>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  value={data.fax || '-'}
                  readOnly
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group mb-2'>
                <label htmlFor='' className='fw-bold fs-6 mb-2'>
                  Contact Name
                </label>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  value={data.contact_name || '-'}
                  readOnly
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group mb-2'>
                <label htmlFor='' className='fw-bold fs-6 mb-2'>
                  BPCS Code
                </label>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  value={data.bpcs_code || '-'}
                  readOnly
                />
              </div>
            </div>
            <div className='col-md-12'>
              <div className='form-group mb-2'>
                <label htmlFor='' className='fw-bold fs-6 mb-2'>
                  Address
                </label>
                <textarea
                  className='form-control form-control-solid'
                  value={data.address || '-'}
                ></textarea>
              </div>
            </div>
            <div className='col-md-2'>
              <div className='form-group mb-2'>
                <label htmlFor='' className='fw-bold fs-6 mb-2'>
                  Active
                </label>
                <div className='mt-2'>{activeFormat(data.active)}</div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {SupShow}
