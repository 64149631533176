import {useState, useRef} from 'react'
import {CSVLink} from 'react-csv'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFileExport} from '@fortawesome/free-solid-svg-icons'
import {ExportDept} from '../../../modules/api/requests/ExportDataRequest'

const DeptExportData = () => {
  const [data, setData] = useState<any>([])
  const csvLink: any = useRef()
  const name = 'departments'

  const headers = [
    {label: 'ID', key: 'index'},
    {label: 'Dept no', key: 'dept_no'},
    {label: 'Dept Name', key: 'dept_name'},
    {label: 'Acc Remark', key: 'acc_remark'},
    {label: 'Dept Alias Name', key: 'dept_alias_name'},
    {label: 'Header Dept PR no', key: 'header_dept_pr_no'},
    {label: 'Factory no', key: 'factory_no'},
    {label: 'Groupleader Increase	', key: 'groupleader_increase'},
    {label: 'Sectionhead Increase', key: 'sectionhead_increase'},
    {label: 'Senior Increase', key: 'senior_increase'},
    {label: 'Depthead Increase	', key: 'depthead_increase'},
    {label: 'Active', key: 'active'},
    {label: 'Created By', key: 'created_by'},
    {label: 'Created At', key: 'created_at'},
    {label: 'Updated By', key: 'updated_by'},
    {label: 'Updated At', key: 'updated_at'},
  ]

  const getData = async () => {
    await ExportDept()
      .then((res) => {
        let rawdata = res.data.data;
        const csvData = rawdata.map((row:any) => {
          return {
            index: `=""${row.index || '-'}""`,
            dept_no: `=""${row.dept_no || '-'}""`,
            dept_name: `=""${row.dept_name || '-'}""`,
            acc_remark: `=""${row.acc_remark || '-'}""`,
            dept_alias_name: `=""${row.dept_alias_name || '-'}""`,
            header_dept_pr_no: `=""${row.header_dept_pr_no || '-'}""`,
            factory_no: `=""${row.factory_no || '-'}""`,
            groupleader_increase: row.groupleader_increase || '-',
            sectionhead_increase: row.sectionhead_increase || '-',
            senior_increase: row.senior_increase || '-',
            depthead_increase: row.depthead_increase || '-',
            active: `=""${row.active || 0}""`,
            created_by: `=""${row.created_by || '-'}""`,
            created_at: `=""${row.created_at || '-'}""`,
            updated_by: `=""${row.updated_by || '-'}""`,
            updated_at: `=""${row.updated_at || '-'}""`,
          }
        })
        setData(csvData)
        setTimeout(() => {
          csvLink.current.link.click()
        }, 200)
      })
      .catch((err) => {
        setData([])
      })
  }

  return (
    <>
      <button
        className='btn btn-light-success'
        onClick={() => {
          getData()
        }}
      >
        <FontAwesomeIcon icon={faFileExport} className='fa-fw me-1' />
        Export
      </button>
      <CSVLink
        data={data}
        filename={name || 'data.csv'}
        headers={headers}
        className='hidden'
        ref={csvLink}
        target='_blank'
      />
    </>
  )
}

export {DeptExportData}
