import {PageTitle} from '../../../../../_metronic/layout/core'
import {useParams, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSave, faXmark} from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'
import {SUPFind, SUPUpdate} from '../../../../modules/api/requests/SupplierRequest'
import {SGOption} from '../../../../modules/api/requests/SupplierGroupRequest'
import {BranchOption} from '../../../../modules/api/requests/BranchRequest'
import Select from 'react-select'
import {useState, useEffect} from 'react'
import * as PATH from '../../../../modules/path/Path'
import {customStyles} from '../../../../modules/widgets/Styles/ReactSelect'
import {useCurrentPage} from '../../HistoryPage'

const SUPForm = () => {
  const navigate = useNavigate()
  const params = useParams()
  const id: string = params.id ?? ''
  const obj = {
    id: 0,
    title: '',
    tel: '',
    email: '',
    fax: '',
    contact_name: '',
    address: '',
    active: 0,
    bpcs_code: '',
    branch_id: 0,
    supplier_group_id: 0,
  }
  const [sup, setSub] = useState(obj)
  const [SGs, setSG] = useState([])
  const [branch, setBranch] = useState([])
  const [checked, setChecked] = useState(false)
  const {currentPage, setCurrentPage, currentPages} = useCurrentPage()

  const getData = async () => {
    const res: any = await SUPFind(id)
    setSub(res.data.data)
    if (res.data.data.active === 1) {
      setChecked(true)
    } else {
      setChecked(false)
    }
  }

  const getSG = async () => {
    let options: any = []
    const res: any = await SGOption()
    if (res.data.data.length > 0) {
      res.data.data.map((val: any) => {
        options.push({value: val.id, label: val.title})
      })
      setSG(options)
    }
  }

  const getBranch = async () => {
    let options: any = []
    const res: any = await BranchOption()
    if (res.data.data.length > 0) {
      res.data.data.map((val: any) => {
        options.push({value: val.id, label: val.title})
      })
      setBranch(options)
    }
  }

  const getPage = () => {
    if (currentPages) {
      currentPages.page = 'suppliers'
      currentPages.status = false
    }
  }

  useEffect(() => {
    getPage()
    getData()
    getSG()
    getBranch()
  }, [])

  const setActive = async (ac: any) => {
    if (ac == true) {
      formik.setFieldValue('active', 1)
    } else if (ac == false) {
      formik.setFieldValue('active', 0)
    }
    setChecked(!checked)
  }

  const comeback = () => {
    if (
      formik.values.title != sup.title ||
      formik.values.tel != sup.tel ||
      formik.values.email != sup.email ||
      formik.values.fax != sup.fax ||
      formik.values.contact_name != sup.contact_name ||
      formik.values.address != sup.address ||
      formik.values.active != sup.active ||
      formik.values.bpcs_code != sup.bpcs_code ||
      formik.values.branch_id != sup.branch_id ||
      formik.values.sg != sup.supplier_group_id
    ) {
      Swal.fire({
        title: 'Are you confirm to cancel?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        heightAuto: false,
        returnFocus: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate(PATH.SUP_LIST)
        }
      })
    } else {
      navigate(PATH.SUP_LIST)
    }
  }

  const IsEmty = () => {
    if (
      !formik.values.title ||
      !formik.values.tel ||
      !formik.values.email ||
      !formik.values.fax ||
      !formik.values.address ||
      !formik.values.bpcs_code ||
      !formik.values.branch_id ||
      !formik.values.sg ||
      !formik.values.contact_name
    ) {
      Swal.fire({
        title: 'Please complete the information.',
        icon: 'error',
        confirmButtonColor: '#3085d6',
        heightAuto: false,
        returnFocus: false,
      })
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: sup.id,
      title: sup.title,
      tel: sup.tel,
      email: sup.email,
      fax: sup.fax,
      contact_name: sup.contact_name,
      address: sup.address,
      active: sup.active,
      bpcs_code: sup.bpcs_code,
      branch_id: sup.branch_id,
      sg: sup.supplier_group_id,
    },
    validationSchema: Yup.object({
      title: Yup.string().max(255, 'Must be 255 characters or less').required('title is required'),
      tel: Yup.string().max(50, 'Must be 50 characters or less').required('tel is required'),
      address: Yup.string().required('address is required'),
      email: Yup.string()
        .email('invalid email format')
        .max(255, 'Must be 255 characters or lesss')
        .required('email is required'),
      fax: Yup.string().max(255, 'Must be 255 characters or less').required('fax is required'),
      contact_name: Yup.string()
        .max(255, 'Must be 255 characters or less')
        .required('contact name is required'),
      active: Yup.number().required('active is required'),
      bpcs_code: Yup.string()
        .max(255, 'Must be 255 characters or less')
        .required('bpcs code is required'),
      branch_id: Yup.number().min(1, 'branch is required').required('branch is required'),
      sg: Yup.number().min(1, 'supplier group is required').required('supplier group is required'),
    }),
    onSubmit: async (values) => {
      Swal.fire({
        title: 'Are you confirm to update?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        heightAuto: false,
        returnFocus: false,
        showLoaderOnConfirm: true,
        preConfirm: (e) => {
          return new Promise(async function (resolve) {
            await SUPUpdate(
              values.id,
              values.title,
              values.tel,
              values.email,
              values.fax,
              values.contact_name,
              values.address,
              values.active,
              values.bpcs_code,
              values.branch_id,
              values.sg
            )
              .then((res) => {
                if (res.data.status == true) {
                  Swal.fire({
                    title: 'Successful updated!',
                    icon: 'success',
                    showConfirmButton: false,
                    heightAuto: false,
                    timer: 1500,
                    returnFocus: false,
                  }).then(() => {
                    navigate(PATH.SUP_LIST)
                  })
                }
              })
              .catch((err) => {
                Swal.fire({
                  title: 'Failed to save',
                  icon: 'error',
                  heightAuto: false,
                  returnFocus: false,
                })
              })
          })
        },
      })
    },
  })
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className='card'>
          <div className='card-header'>
            <div className='card-title'>
              <h3>Supplier - Edit</h3>
            </div>
            <div className='card-toolbar'>
              <button type='button' className='btn btn-danger shadow-sm me-2' onClick={comeback}>
                <FontAwesomeIcon icon={faXmark} className='fa-fw' />
                Cancel
              </button>
              <button type='submit' className='btn btn-primary shadow-sm' onClick={IsEmty}>
                <FontAwesomeIcon icon={faSave} className='fa-fw' />
                Save
              </button>
            </div>
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col-md-12'>
                {/* title */}
                <div className='form-group mb-4'>
                  <label htmlFor='title' className='fw-bold required mb-2'>
                    Title
                  </label>
                  <input
                    type='text'
                    id='title'
                    name='title'
                    className={'form-control form-control-sm'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.title}
                  />
                  {formik.touched.title && formik.errors.title ? (
                    <div className='text-danger'>{formik.errors.title}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group mb-4'>
                  <label htmlFor='title' className='fw-bold required mb-2'>
                    Supplier Group
                  </label>
                  <Select
                    placeholder={'Please Select Supplier Group'}
                    options={SGs}
                    value={SGs ? SGs.find((option: any) => option.value === formik.values.sg) : ''}
                    onChange={(option: any) => formik.setFieldValue('sg', option.value)}
                    styles={customStyles}
                  />
                  {formik.touched.sg && formik.errors.sg ? (
                    <div className='text-danger'>{formik.errors.sg}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group mb-4'>
                  <label htmlFor='title' className='fw-bold required mb-2'>
                    Branch
                  </label>
                  <Select
                    placeholder={'Please Select Branch'}
                    options={branch}
                    value={
                      branch
                        ? branch.find((option: any) => option.value === formik.values.branch_id)
                        : ''
                    }
                    onChange={(option: any) => formik.setFieldValue('branch_id', option.value)}
                    styles={customStyles}
                  />
                  {formik.touched.branch_id && formik.errors.branch_id ? (
                    <div className='text-danger'>{formik.errors.branch_id}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-4'>
                {/* tel */}
                <div className='form-group mb-4'>
                  <label htmlFor='title' className='fw-bold required mb-2'>
                    Tel
                  </label>
                  <input
                    type='text'
                    id='tel'
                    name='tel'
                    className={'form-control form-control-sm'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.tel}
                  />
                  {formik.touched.tel && formik.errors.tel ? (
                    <div className='text-danger'>{formik.errors.tel}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-4'>
                {/* email */}
                <div className='form-group mb-4'>
                  <label htmlFor='email' className='fw-bold required mb-2'>
                    Email
                  </label>
                  <input
                    type='text'
                    id='email'
                    name='email'
                    className={'form-control form-control-sm'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className='text-danger'>{formik.errors.email}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-4'>
                {/* Fax */}
                <div className='form-group mb-4'>
                  <label htmlFor='fax' className='fw-bold required mb-2'>
                    Fax
                  </label>
                  <input
                    type='text'
                    id='fax'
                    name='fax'
                    className={'form-control form-control-sm'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.fax}
                  />
                  {formik.touched.fax && formik.errors.fax ? (
                    <div className='text-danger'>{formik.errors.fax}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-4'>
                {/* Contact Name */}
                <div className='form-group mb-4'>
                  <label htmlFor='contact_name' className='fw-bold required mb-2'>
                    Contact Name
                  </label>
                  <input
                    type='text'
                    id='contact_name'
                    name='contact_name'
                    className={'form-control form-control-sm'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.contact_name}
                  />
                  {formik.touched.contact_name && formik.errors.contact_name ? (
                    <div className='text-danger'>{formik.errors.contact_name}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-4'>
                {/* BPCS CODE */}
                <div className='form-group mb-4'>
                  <label htmlFor='bpcs_code' className='fw-bold required mb-2'>
                    BPCS Code
                  </label>
                  <input
                    type='text'
                    id='bpcs_code'
                    name='bpcs_code'
                    className={'form-control form-control-sm'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.bpcs_code}
                  />
                  {formik.touched.bpcs_code && formik.errors.bpcs_code ? (
                    <div className='text-danger'>{formik.errors.bpcs_code}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-4'>
                <div className='form-group mb-4'>
                  <label htmlFor='active' className='fw-bold mb-2'>
                    Active
                  </label>
                  <div className='mt-2'>
                    <div className='form-check form-switch'>
                      <input
                        className='form-check-input'
                        checked={checked}
                        type='checkbox'
                        id='active'
                        name='active'
                        value={formik.values.active}
                        onChange={(e) => {
                          setActive(e.target.checked)
                        }}
                      />
                    </div>
                  </div>
                  {formik.touched.active && formik.errors.active ? (
                    <div className='text-danger'>{formik.errors.active}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-12'>
                {/* address */}
                <div className='form-group mb-4'>
                  <label htmlFor='title' className='fw-bold mb-2'>
                    Address <span className='text-danger'>*</span>
                  </label>
                  <textarea
                    name='address'
                    id='address'
                    className={'form-control form-control-sm'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.address}
                  ></textarea>
                  {formik.touched.address && formik.errors.address ? (
                    <div className='text-danger'>{formik.errors.address}</div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

const breadcrumbs = [
  {
    title: 'Home',
    path: '/',
    isActive: false,
  },
  {
    title: '',
    path: '',
    isActive: false,
    isSeparator: true,
  },
  {
    title: 'Supplier',
    path: PATH.SUP_LIST,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isActive: false,
    isSeparator: true,
  },
]

const SUPEdit = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Supplier - Edit</PageTitle>
      <SUPForm />
    </>
  )
}

export default SUPEdit
