import {KTSVG} from '../../../../_metronic/helpers'
import {useState} from 'react'
import {Modal, Button} from 'react-bootstrap'

const OpenFile = (file: string, local: string) => {
  const FILE = process.env.REACT_APP_FILE_API_URL

  const open = (url: string) => {
    window.open(FILE + url)
  }

  if (file == '' || file == null || typeof file === 'undefined') {
    return <div className='text-center'>-</div>
  } else {
    const filename = file.split('.') || []
    const type = filename[filename.length - 1] || ''
    const url = '/' + local + '/' + file

    if (type == 'pdf') {
      return (
        <>
          <button
            className='btn btn-icon'
            onClick={() => {
              open(url)
            }}
            title={file}
          >
            <KTSVG path='/media/icons/duotune/file/pdf-2.svg' className='svg-icon-1' />
          </button>
        </>
      )
    } else if (type == 'xls' || type == 'xlsx' || type == 'csv') {
      return (
        <>
          <button
            className='btn btn-icon'
            onClick={() => {
              open(url)
            }}
            title={file}
          >
            <KTSVG path='/media/icons/duotune/file/xls-2.svg' className='svg-icon-1' />
          </button>
        </>
      )
    } else if (type == 'txt') {
      return (
        <>
          <button
            className='btn btn-icon'
            onClick={() => {
              open(url)
            }}
            title={file}
          >
            <KTSVG path='/media/icons/duotune/file/txt-2.svg' className='svg-icon-1' />
          </button>
        </>
      )
    } else if (type == 'psd') {
      return (
        <>
          <button
            className='btn btn-icon'
            onClick={() => {
              open(url)
            }}
            title={file}
          >
            <KTSVG path='/media/icons/duotune/file/psd-2.svg' className='svg-icon-1' />
          </button>
        </>
      )
    } else if (type == 'zip') {
      return (
        <>
          <button
            className='btn btn-icon'
            onClick={() => {
              open(url)
            }}
            title={file}
          >
            <KTSVG path='/media/icons/duotune/file/7z.svg' className='svg-icon-1' />
          </button>
        </>
      )
    } else if (type == 'raw') {
      return (
        <>
          <button
            className='btn btn-icon'
            onClick={() => {
              open(url)
            }}
            title={file}
          >
            <KTSVG path='/media/icons/duotune/file/rar.svg' className='svg-icon-1' />
          </button>
        </>
      )
    } else if (type == 'ai') {
      return (
        <>
          <button
            className='btn btn-icon'
            onClick={() => {
              open(url)
            }}
            title={file}
          >
            <KTSVG path='/media/icons/duotune/file/ai-2.svg' className='svg-icon-1' />
          </button>
        </>
      )
    } else if (type == 'doc' || type == 'docx') {
      return (
        <>
          <button
            className='btn btn-icon'
            onClick={() => {
              open(url)
            }}
            title={file}
          >
            <KTSVG path='/media/icons/duotune/file/doc-2.svg' className='svg-icon-1' />
          </button>
        </>
      )
    } else if (type == 'ppt') {
      return (
        <>
          <button
            className='btn btn-icon'
            onClick={() => {
              open(url)
            }}
            title={file}
          >
            <KTSVG path='/media/icons/duotune/file/ptt-2.svg' className='svg-icon-1' />
          </button>
        </>
      )
    } else if (type == 'mp3') {
      return (
        <>
          <button
            className='btn btn-icon'
            onClick={() => {
              open(url)
            }}
            title={file}
          >
            <KTSVG path='/media/icons/duotune/file/mp3-2.svg' className='svg-icon-1' />
          </button>
        </>
      )
    } else if (type == 'mp4') {
      return (
        <>
          <button
            className='btn btn-icon'
            onClick={() => {
              open(url)
            }}
            title={file}
          >
            <KTSVG path='/media/icons/duotune/file/mp4.svg' className='svg-icon-1' />
          </button>
        </>
      )
    } else if (type == 'png' || type == 'PNG') {
      return (
        <>
          <button
            className='btn btn-icon'
            onClick={() => {
              open(url)
            }}
            title={file}
          >
            <KTSVG path='/media/icons/duotune/file/png.svg' className='svg-icon-1' />
          </button>
        </>
      )
    } else if (type == 'GIF' || type == 'gif') {
      return (
        <>
          <button
            className='btn btn-icon'
            onClick={() => {
              open(url)
            }}
            title={file}
          >
            <KTSVG path='/media/icons/duotune/file/gif.svg' className='svg-icon-1' />
          </button>
        </>
      )
    } else if (type == 'jpg' || type == 'jpeg') {
      return (
        <>
          <button
            className='btn btn-icon'
            onClick={() => {
              open(url)
            }}
            title={file}
          >
            <KTSVG path='/media/icons/duotune/file/jpg-2.svg' className='svg-icon-1' />
          </button>
        </>
      )
    } else if (type == 'svg' || type == 'SVG') {
      return (
        <>
          <button
            className='btn btn-icon'
            onClick={() => {
              open(url)
            }}
            title={file}
          >
            <KTSVG path='/media/icons/duotune/file/svg-2.svg' className='svg-icon-1' />
          </button>
        </>
      )
    } else if (type == 'sql') {
      return (
        <>
          <button
            className='btn btn-icon'
            onClick={() => {
              open(url)
            }}
            title={file}
          >
            <KTSVG path='/media/icons/duotune/file/sql.svg' className='svg-icon-1' />
          </button>
        </>
      )
    } else {
      return (
        <>
          <button
            className='btn btn-icon'
            onClick={() => {
              open(url)
            }}
            title={file}
          >
            <KTSVG path='/media/icons/duotune/files/fil003.svg' className='svg-icon-1' />
          </button>
        </>
      )
    }
  }
}

export {OpenFile}
