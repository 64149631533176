import axios from 'axios'
import {DCModel} from '../models/DCModel'

const API_URL = process.env.REACT_APP_API_URL

export const DC = `${API_URL}/document-concerned`
export const DC_FIND = `${API_URL}/document-concerned/find`
export const DC_OPTION = `${API_URL}/documentConcerned-option`

export function DCList(limit: number, skip: number, search: any) {
  return axios.get<DCModel>(DC + `/${limit}&${skip}&${search || null}`)
}

export function DCFind(id: string) {
  return axios.get<DCModel>(DC_FIND + `/${id}`)
}

export function DCSave(title: string, description?: string, active?: number) {
  return axios.post(DC, {
    title,
    description,
    active,
  })
}

export function DCUpdate(id: number, title: string, description?: string, active?: number) {
  return axios.put(DC, {
    id,
    title,
    description,
    active,
  })
}

export function DCDel(id: number) {
  return axios.delete(DC + `/${id}`)
}

export function DCOption() {
  return axios.get(DC_OPTION)
}
