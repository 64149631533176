import {useState, useEffect, useMemo, useRef, useCallback} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {QSList, QSDel} from '../../modules/api/requests/QuotationRequest'
import {QSTable} from '../../modules/widgets/quotation/QuotationTable'
import {KTSVG} from '../../../_metronic/helpers'
import {CoverTime3, CovertTime} from '../../modules/widgets/func/CoverTime'
import {ReturnNull} from '../../modules/widgets/func/ReturnNull'
import {statusFormat} from '../../modules/widgets/quotation/func/QSStatus'
import {ReadMore} from '../../modules/widgets/func/ReadMore'
import {QSShow} from './Show'
import {QSEdit} from './Edit'
import {useAuth} from '../../modules/auth'
import Swal from 'sweetalert2'
import {QSExportData} from './Export'
import {BGLoad} from '../../modules/widgets/func/BackgroundLoading'

const QSForm = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const fetchIdRef = useRef(0)
  let filters = ''
  let index = 0
  let size = 0

  const fetchAPI = async ({limit, skip, search, newdate, status}: any) => {
    try {
      let lt = limit || 10
      let sk = skip || 0
      let sr: any = encodeURIComponent(JSON.stringify(search || null))
      let nd: any = encodeURIComponent(JSON.stringify(newdate || null))
      let st: any = encodeURIComponent(JSON.stringify(status || null))

      const res: any = await QSList(lt, sk, sr, nd, st)
      let data: any = res.data.data
      let count: number = res.data.count
      count = count / 1
      setData(data)
      setPageCount(Math.ceil(count / limit))
      setLoading(false)
    } catch (err) {
      console.log('Error while fecth')
    }
  }

  const fetchData = useCallback(({pageSize, pageIndex, filter, newdate, status}: any) => {
    const fetchId = ++fetchIdRef.current
    setLoading(true)
    index = pageIndex
    size = pageSize
    filters = filter
    if (fetchId == fetchIdRef.current) {
      fetchAPI({
        limit: pageSize,
        skip: pageSize * pageIndex,
        search: filter,
        newdate: newdate,
        status: status,
      })
    }
  }, [])

  const getData = () => {
    fetchAPI({
      limit: size || 10,
      skip: size * index || 0,
      search: filters || null,
      newdate: null,
      status: null,
    })
  }

  const del = async (id: number) => {
    Swal.fire({
      title: 'Are you confirm to delete?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      heightAuto: false,
      showLoaderOnConfirm: true,
      returnFocus: false,
      preConfirm: (e) => {
        return new Promise(async function (resolve) {
          await QSDel(id)
            .then((res) => {
              if (res.data.status == true) {
                Swal.fire({
                  title: 'Successful deleted!',
                  icon: 'success',
                  showConfirmButton: false,
                  heightAuto: false,
                  returnFocus: false,
                  timer: 1500,
                }).then(() => {
                  getData()
                })
              }
            })
            .catch((err) => {
              Swal.fire({
                title: 'Failed to delete',
                icon: 'error',
                heightAuto: false,
                returnFocus: false,
              })
            })
        })
      },
    })
  }

  const columns = useMemo(
    () => [
      {
        Header: <div>Job&nbsp;no.</div>,
        accessor: 'job_title',
        headerClassName: 'min-w-50px headcol',
        columnClassName: 'headcol',
        Cell: ({cell}: any) => <span>{ReturnNull(cell.row.original.job_title)}</span>,
      },
      {
        Header: <div>Job&nbsp;User&nbsp;no.</div>,
        accessor: 'job_user_no',
        headerClassName: 'min-w-50px',
        Cell: ({cell}: any) => (
          <span className=''>{ReturnNull(cell.row.original.job_user_no)}</span>
        ),
      },
      {
        Header: <div>Dept./Sect.&nbsp;Code</div>,
        id: 'dept_id',
        headerClassName: 'min-w-100px',
        Cell: ({cell}: any) => (
          <span className='word-break'>{ReadMore(cell.row.original.dept_title)}</span>
        ),
      },
      {
        Header: <div>For&nbsp;dept.</div>,
        id: 'for_dept_id',
        headerClassName: 'min-w-100px',
        Cell: ({cell}: any) => (
          <span className='word-break'>{ReadMore(cell.row.original.for_dept_title)}</span>
        ),
      },
      {
        Header: <div>Group&nbsp;from&nbsp;System</div>,
        accessor: 'ig_title',
        headerClassName: 'min-w-100px',
      },
      {
        Header: <div>Item</div>,
        id: 'item_title',
        headerClassName: 'min-w-100px',
        Cell: ({cell}: any) => (
          <span className='word-break'>
            {ReadMore(cell.row.original.item_title || cell.row.original.item_other)}
          </span>
        ),
      },
      {
        Header: <div>Item&nbsp;Description</div>,
        id: 'item_description',
        headerClassName: 'min-w-100px',
        Cell: ({cell}: any) => (
          <span className='word-break'>{ReadMore(cell.row.original.item_description)}</span>
        ),
      },
      {
        Header: <div>Request&nbsp;Date</div>,
        id: 'created_at',
        headerClassName: 'min-w-100px',
        Cell: ({cell}: any) => <span>{CoverTime3(cell.row.original.created_at)}</span>,
      },
      {
        Header: <div>Finished&nbsp;Date</div>,
        id: 'finished_date',
        headerClassName: 'min-w-100px',
        Cell: ({cell}: any) => <span>{CoverTime3(cell.row.original.finished_date)}</span>,
      },
      {
        Header: <div>Status</div>,
        id: 'status',
        headerClassName: 'min-w-100px',
        Cell: ({cell}: any) => {
          return <span>{statusFormat(cell.row.original.status)}</span>
        },
      },
      {
        Header: <div>Comment</div>,
        id: 'comment',
        headerClassName: 'min-w-150px',
        Cell: ({cell}: any) => (
          <span className='word-break'>{ReadMore(cell.row.original.gp_verify_comment)}</span>
        ),
      },
      {
        Header: <div>PIC</div>,
        id: 'pic',
        headerClassName: 'min-w-100px',
        Cell: ({cell}: any) => <span>{ReturnNull(cell.row.original.pic)}</span>,
      },
      // {
      //   Header: <div>PR.no</div>,
      //   id: 'gp_verify_id',
      //   headerClassName: 'min-w-100px',
      //   Cell: ({cell}: any) => <span>{ReturnNull(cell.row.original.gp_verify_id)}</span>,
      // },
      {
        Header: 'Action',
        id: 'action',
        headerClassName: 'text-end headcol-right',
        columnClassName: 'rowcol-right',
        Cell: ({cell}: any) => (
          <div className='d-flex justify-content-end flex-shrink-0'>
            <QSEdit data={cell.row.original} getData={getData} />
            <QSShow data={cell.row.original} />
            <button
              className='btn btn-icon btn-light-danger btn-sm'
              onClick={() => {
                del(cell.row.original.id)
              }}
            >
              <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
            </button>
          </div>
        ),
      },
    ],
    []
  )

  return (
    <>
      <QSTable
        columns={columns}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
      />
    </>
  )
}

const breadcrumbs = [
  {
    title: 'Home',
    path: '/',
    isActive: false,
  },
  {
    title: '',
    path: '',
    isActive: false,
    isSeparator: true,
  },
]

const QSIndex = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Quotation Report</PageTitle>
      <QSForm />
      <BGLoad />
    </>
  )
}

export default QSIndex
