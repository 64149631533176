const statusFormat = (status: string) => {
  if (status == 'none') {
    return <span className='badge badge-light-secondary text-dark'>none</span>
  } else if (status == 'return') {
    return <span className='badge badge-light-danger'>return</span>
  } else if (status == 'submit') {
    return <span className='badge badge-light-warning'>submit</span>
  } else if (status == 'processing') {
    return <span className='badge badge-light-info'>processing</span>
  } else if (status == 'finished') {
    return <span className='badge badge-light-success'>finished</span>
  } else if (status == 'cancel') {
    return <span className='badge badge-danger text-white'>cancel</span>
  } else if (status == 'revised') {
    return <span className='badge badge-light-orange'>revised</span>
  }
}

export {statusFormat}
