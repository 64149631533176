import {useState, useRef} from 'react'
import {CSVLink} from 'react-csv'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFileExport} from '@fortawesome/free-solid-svg-icons'
import {ExportBranch} from '../../../modules/api/requests/ExportDataRequest'

const BranchExportData = () => {
  const [data, setData] = useState<any>([])
  const csvLink: any = useRef()
  const name = 'branches'

  const headers = [
    {label: 'ID', key: 'index'},
    {label: 'Title', key: 'title'},
    {label: 'Description', key: 'description'},
    {label: 'Active', key: 'active'},
    {label: 'Created By', key: 'created_by'},
    {label: 'Created At', key: 'created_at'},
    {label: 'Updated By', key: 'updated_by'},
    {label: 'Updated At', key: 'updated_at'},
  ]

  const getData = async () => {
    await ExportBranch()
      .then((res) => {
        let rawdata = res.data.data;
        const csvData = rawdata.map((row:any) => {
          return {
            index: `=""${row.index || '-'}""`,
            title: `=""${row.title || '-'}""`,
            description: `=""${row.description || '-'}""`,
            active: `=""${row.active || 0}""`,
            created_by: `=""${row.created_by || '-'}""`,
            created_at: `=""${row.created_at || '-'}""`,
            updated_by: `=""${row.updated_by || '-'}""`,
            updated_at: `=""${row.updated_at || '-'}""`,
          }
        })
        setData(csvData)
        setTimeout(() => {
          csvLink.current.link.click()
        }, 200)
      })
      .catch((err) => {
        setData([])
      })
  }

  return (
    <>
      <button
        className='btn btn-light-success'
        onClick={() => {
          getData()
        }}
      >
        <FontAwesomeIcon icon={faFileExport} className='fa-fw me-1' />
        Export
      </button>
      <CSVLink
        data={data}
        filename={name || 'data.csv'}
        headers={headers}
        className='hidden'
        ref={csvLink}
        target='_blank'
      />
    </>
  )
}

export {BranchExportData}
