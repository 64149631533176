const BGLoad = () => {
  return (
    <div className='bg-loading' style={{display: 'none'}} id='bg_load'>
      <div className='text-center box-loading'>
        <div className='loadingio-spinner-spinner-2c4dcz2cbtb'>
          <div className='ldio-pojnk8my9wr'>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  )
}

const BGClose = () => {
  let box = document.getElementById(`bg_load`) as HTMLInputElement
  box.style.display = 'none'
}

export {BGLoad, BGClose}
