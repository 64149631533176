import {useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Modal, Button} from 'react-bootstrap'
import {url} from 'inspector'

const ReturnImage = (str: string) => {
  const [show, setShow] = useState(false)
  const FILE = process.env.REACT_APP_FILE_API_URL

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  // const fullImage = (style: any) => {
  //   console.log(style)
  //   let fullpage = document.getElementById(`fullpage`) as HTMLInputElement
  //   fullpage.style.backgroundImage = 'url(`https://mdbootstrap.com/img/new/standard/city/041.jpg`)'
  //   fullpage.style.display = style
  // }

  if (str == '' || str == null || typeof str === 'undefined') {
    return <div className='text-center'>-</div>
  } else {
    return (
      <>
        <button className='btn btn-icon' onClick={handleShow}>
          <KTSVG path='/media/icons/duotune/file/image-2.svg' className='svg-icon-1' />
        </button>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>PIC</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='text-center'>
              <img
                className='img-fluid'
                src={FILE + `/pic/${str}`}
                alt=''
                // onClick={() => {
                //   fullImage('block')
                // }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* <div
          id='fullpage'
          onClick={() => {
            fullImage('none')
          }}
        ></div> */}
      </>
    )
  }
}

export {ReturnImage}
