import axios from 'axios'
import {ItemGroupModel} from '../models/ItemGroupModel'

const API_URL = process.env.REACT_APP_API_URL

export const ITEM_GROUP = `${API_URL}/item-group`
export const ITEM_GROUP_FIND = `${API_URL}/item-group/find`
export const ITEM_GROUP_OPTION = `${API_URL}/itemGroup-option`

export function ItemGroupList(limit: number, skip: number, search: any) {
  return axios.get<ItemGroupModel>(ITEM_GROUP + `/${limit}&${skip}&${search || null}`)
}

export function ItemGroupSave(
  title: string,
  description?: string,
  custom_item?: number,
  active?: number
) {
  return axios.post(ITEM_GROUP, {
    title,
    description,
    custom_item,
    active,
  })
}

export function ItemGroupFind(id: string) {
  return axios.get<ItemGroupModel>(ITEM_GROUP_FIND + `/${id}`)
}

export function ItemGroupUpdate(
  id: number,
  title: string,
  description?: string,
  custom_item?: number,
  active?: number
) {
  return axios.put(ITEM_GROUP, {
    id,
    title,
    description,
    custom_item,
    active,
  })
}

export function ItemGroupDel(id: number) {
  return axios.delete(ITEM_GROUP + `/${id}`)
}

export function ItemGroupOption() {
  return axios.get(ITEM_GROUP_OPTION)
}
