import axios from 'axios'
import {ItemModel} from '../models/ItemModel'

const API_URL = process.env.REACT_APP_API_URL

export const ITEM = `${API_URL}/item`
export const ITEM_FIND = `${API_URL}/item/find`
export const ITEM_OPTION = `${API_URL}/item-option`

export function ItemList(limit: number, skip: number, search: any) {
  return axios.get<ItemModel>(ITEM + `/${limit}&${skip}&${search || null}`)
}

export function ItemSave(
  title: string,
  price: number,
  qty: number,
  item_group_id?: string,
  unit_id?: string,
  description?: string,
  active?: number
) {
  return axios.post(ITEM, {
    title,
    price,
    qty,
    item_group_id,
    unit_id,
    description,
    active,
  })
}

export function ItemFind(id: string) {
  return axios.get<ItemModel>(ITEM_FIND + `/${id}`)
}

export function ItemUpdate(
  id: number,
  title: string,
  price: number,
  qty: number,
  item_group_id?: string,
  unit_id?: string,
  description?: string,
  active?: number
) {
  return axios.put(ITEM, {
    id,
    title,
    price,
    qty,
    item_group_id,
    unit_id,
    description,
    active,
  })
}

export function ItemDel(id: number) {
  return axios.delete(ITEM + `/${id}`)
}

export function ItemOption() {
  return axios.get(ITEM_OPTION)
}
