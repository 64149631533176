import React from 'react'
import {useEffect, useState} from 'react'
import {useParams, useNavigate} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {BranchFind, BranchUpdate} from '../../../modules/api/requests/BranchRequest'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSave, faXmark} from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'
import * as PATH from '../../../modules/path/Path'
import {useCurrentPage} from '../HistoryPage'

const BranchForm = () => {
  const obj = {id: 0, title: '', description: '', active: 0}
  const [branch, setBranch] = useState(obj)
  const [checked, setChecked] = useState(false)
  const navigate = useNavigate()
  const params = useParams()
  const id: string = params.id ?? ''
  const {currentPage, setCurrentPage, currentPages} = useCurrentPage()

  const getData = async () => {
    const res: any = await BranchFind(id)
    if (res) {
      if (res.data.status == true) {
        setBranch(res.data.data)
        if (res.data.data.active === 1) {
          setChecked(true)
        } else {
          setChecked(false)
        }
      }
    }
  }

  const setActive = async (ac: any) => {
    if (ac == true) {
      formik.setFieldValue('active', 1)
    } else if (ac == false) {
      formik.setFieldValue('active', 0)
    }
    setChecked(!checked)
  }

  const getPage = () => {
    if (currentPages) {
      currentPages.page = 'branches'
      currentPages.status = false
    }
  }

  useEffect(() => {
    getPage()
    getData()
  }, [])

  const IsEmty = () => {
    if (!formik.values.title) {
      Swal.fire({
        title: 'Please complete the information.',
        icon: 'error',
        confirmButtonColor: '#3085d6',
        heightAuto: false,
        returnFocus: false,
      })
    }
  }

  const comeback = () => {
    if (
      formik.values.title != branch.title ||
      formik.values.description != branch.description ||
      formik.values.active != branch.active
    ) {
      Swal.fire({
        title: 'Are you confirm to cancel?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        heightAuto: false,
        returnFocus: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate(PATH.BRANCH_LIST)
        }
      })
    } else {
      navigate(PATH.BRANCH_LIST)
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: branch.id,
      title: branch.title,
      description: branch.description,
      active: branch.active,
    },
    validationSchema: Yup.object({
      title: Yup.string().max(255, 'Must be 255 characters or less').required('Title is required'),
    }),
    onSubmit: async (values) => {
      console.log(values)
      Swal.fire({
        title: 'Are you confirm to update?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        heightAuto: false,
        returnFocus: false,
        showLoaderOnConfirm: true,
        preConfirm: (e) => {
          return new Promise(async function (resolve) {
            await BranchUpdate(values.id, values.title, values.description, values.active)
              .then((res) => {
                if (res.data.status == true) {
                  Swal.fire({
                    title: 'Successful updated!',
                    icon: 'success',
                    showConfirmButton: false,
                    heightAuto: false,
                    timer: 1500,
                    returnFocus: false,
                  }).then(() => {
                    navigate(PATH.BRANCH_LIST)
                  })
                }
              })
              .catch((err) => {
                Swal.fire({
                  title: 'Failed to save',
                  icon: 'error',
                  heightAuto: false,
                  returnFocus: false,
                })
              })
          })
        },
      })
    },
  })

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className='card'>
          <div className='card-header'>
            <div className='card-title'>
              <h3>Branch - Edit</h3>
            </div>
            <div className='card-toolbar'>
              <button type='button' className='btn btn-danger shadow-sm me-2' onClick={comeback}>
                <FontAwesomeIcon icon={faXmark} className='fa-fw' />
                Cancel
              </button>
              <button type='submit' className='btn btn-primary shadow-sm' onClick={IsEmty}>
                <FontAwesomeIcon icon={faSave} className='fa-fw' />
                Save
              </button>
            </div>
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col-md-12'>
                {/* title */}
                <div className='form-group mb-4'>
                  <label htmlFor='title' className='fw-bold required mb-2'>
                    Title
                  </label>
                  <input
                    type='text'
                    id='title'
                    name='title'
                    className={'form-control form-control-sm'}
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.title && formik.errors.title ? (
                    <div className='text-danger'>{formik.errors.title}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-12'>
                {/* description */}
                <div className='form-group mb-4'>
                  <label htmlFor='description' className='fw-bold mb-2'>
                    Description
                  </label>
                  <textarea
                    id='description'
                    name='description'
                    className={'form-control form-control-sm'}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  ></textarea>
                  {formik.touched.description && formik.errors.description ? (
                    <div className='text-danger'>{formik.errors.description}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-12'>
                {/* active */}
                <div className='form-group mb-4'>
                  <label htmlFor='active' className='fw-bold mb-2'>
                    Active
                  </label>
                  <div className='mt-2'>
                    <div className='form-check form-switch'>
                      <input
                        className='form-check-input'
                        checked={checked}
                        type='checkbox'
                        id='active'
                        name='active'
                        value={formik.values.active}
                        onChange={(e) => {
                          setActive(e.target.checked)
                        }}
                      />
                    </div>
                  </div>
                  {formik.touched.active && formik.errors.active ? (
                    <div className='text-danger'>{formik.errors.active}</div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

const breadcrumbs = [
  {
    title: 'Home',
    path: '/',
    isActive: false,
  },
  {
    title: '',
    path: '',
    isActive: false,
    isSeparator: true,
  },
  {
    title: 'Branch',
    path: PATH.BRANCH_LIST,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isActive: false,
    isSeparator: true,
  },
]

const BranchEdit = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Branch - Edit</PageTitle>
      <BranchForm />
    </>
  )
}

export default BranchEdit
