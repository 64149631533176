import {UploadPIC, UploadDRAW, UploadQS} from '../../../api/requests/QuotationRequest'
import Swal from 'sweetalert2'

//---- use edit quotation ---- //

const UploadFiles = (type: string, data: any) => {
  let box = document.getElementById(`bg_load`) as HTMLInputElement
  let input = document.createElement('input')
  input.type = 'file'
  box.style.display = 'block'
  if(type == 'pic'){
    input.accept = 'image/*'
  }
  input.onchange = async (e: any) => {
    let file = e.target.files[0]
    let size: number = file.size || 0
    box.style.display = 'none'
    if (size <= 2000000) {
      Swal.fire({
        title: 'Are you confirm to upload?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        heightAuto: false,
        returnFocus: false,
        showLoaderOnConfirm: true,
        preConfirm: (e) => {
          if (type == 'pic') {
            return new Promise(async function (resolve) {
              uploadPIC(file, data)
            })
          } else if (type == 'draw') {
            return new Promise(async function (resolve) {
              uploadDraw(file, data)
            })
          } else if (type == 'qs') {
            return new Promise(async function (resolve) {
              uploadQS(file, data)
            })
          }
        },
      })
    } else {
      Swal.fire({
        title: 'File size not more than 2 MB.',
        icon: 'error',
        heightAuto: false,
        returnFocus: false,
      })
    }
  }
  input.click()
}

const uploadPIC = async (file: any, data: any) => {
  if (file['type'].split('/')[0] === 'image') {
    var form = new FormData()
    form.append('file', file, file.name)
    await UploadPIC(form)
      .then((res) => {
        if (res.data.status == true) {
          Swal.fire({
            title: 'Successful upload!',
            icon: 'success',
            showConfirmButton: false,
            heightAuto: false,
            timer: 1500,
            returnFocus: false,
          })
          data((newdata: any) => ({...newdata, file_url_pic: res.data.message}))
        }
      })
      .catch((err) => {
        Swal.fire({
          title: 'Failed to save',
          text: 'Please try again.',
          icon: 'error',
          heightAuto: false,
          returnFocus: false,
        })
      })
  } else {
    Swal.fire({
      title: 'Image file only',
      text: 'Only image files are supported.',
      icon: 'error',
      heightAuto: false,
      returnFocus: false,
    })
  }
}

export const uploadDraw = async (file: any, data: any) => {
  var form = new FormData()
  form.append('file', file, file.name)
  await UploadDRAW(form)
    .then((res) => {
      if (res.data.status == true) {
        Swal.fire({
          title: 'Successful upload!',
          icon: 'success',
          showConfirmButton: false,
          heightAuto: false,
          timer: 1500,
          returnFocus: false,
        })
        data((newdata: any) => ({...newdata, file_url_draw: res.data.message}))
      }
    })
    .catch((err) => {
      Swal.fire({
        title: 'Failed to save',
        text: 'Please try again.',
        icon: 'error',
        heightAuto: false,
        returnFocus: false,
      })
    })
}

export const uploadQS = async (file: any, data: any) => {
  var form = new FormData()
  form.append('file', file, file.name)
  await UploadQS(form)
    .then((res) => {
      if (res.data.status == true) {
        Swal.fire({
          title: 'Successful upload!',
          icon: 'success',
          showConfirmButton: false,
          heightAuto: false,
          timer: 1500,
          returnFocus: false,
        })
        data((newdata: any) => ({...newdata, file_url_qto: res.data.message}))
      }
    })
    .catch((err) => {
      Swal.fire({
        title: 'Failed to save',
        text: 'Please try again.',
        icon: 'error',
        heightAuto: false,
        returnFocus: false,
      })
    })
}

export {UploadFiles}
