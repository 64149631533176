import {useState, useMemo, useRef, useCallback, useEffect} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {KTSVG} from '../../../../_metronic/helpers'
import {UserLog, UserDel} from '../../../modules/api/requests/UserRequest'
import {TablePagination} from '../../../modules/widgets/table/TablePagination'
import {CoverTime4, CovertTime} from '../../../modules/widgets/func/CoverTime'
import {ReturnNull} from '../../../modules/widgets/func/ReturnNull'
import {ReadMore} from '../../../modules/widgets/func/ReadMore'
import {UserEdit} from './edit'
import {UserExportData} from './export'
import Swal from 'sweetalert2'
import * as PATH from '../../../modules/path/Path'

const UserForm = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const fetchIdRef = useRef(0)
  const [filter, setFilter] = useState('')
  let index = 0
  let size = 0
  let filters = ''

  const fetchAPI = async ({limit, skip, search}: any) => {
    try {
      let lt = limit || 10
      let sk = skip || 0
      let sr: any = encodeURIComponent(JSON.stringify(search || null))
      const res: any = await UserLog(lt, sk, sr)
      let data: any = res.data.data
      let count: number = res.data.count
      setData(data)
      setPageCount(Math.ceil(count / limit))
      setLoading(false)
    } catch (err) {
      console.log('Error while fecth')
    }
  }

  const fetchData = useCallback(({pageSize, pageIndex, filter}: any) => {
    const fetchId = ++fetchIdRef.current
    setLoading(true)
    index = pageIndex
    size = pageSize
    filters = filter
    if (fetchId == fetchIdRef.current) {
      fetchAPI({limit: pageSize, skip: pageSize * pageIndex, search: filter})
    }
  }, [])

  const getData = () => {
    fetchAPI({limit: size || 10, skip: size * index || 0, search: filters || ''})
  }

  const del = async (id: number) => {
    Swal.fire({
      title: 'Are you confirm to delete?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      heightAuto: false,
      returnFocus: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await UserDel(id)
          .then((res) => {
            if (res.data.status == true) {
              Swal.fire({
                title: 'Successful deleted!',
                icon: 'success',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500,
                returnFocus: false,
              }).then(() => {
                getData()
              })
            }
          })
          .catch((err) => {
            Swal.fire({
              title: 'Failed to delete',
              icon: 'error',
              heightAuto: false,
              returnFocus: false,
            })
          })
      }
    })
  }

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        id: 'index',
        headerClassName: 'min-w-50px',
        accessor: (_row: any, i: number) => i + 1 + size * index,
      },
      {
        Header: 'EmID',
        accessor: 'emid',
        headerClassName: 'min-w-50px',
      },
      {
        Header: 'User',
        id: 'users',
        headerClassName: 'min-w-150px',
        Cell: ({cell}: any) => (
          <div className='d-flex justify-content-center flex-column'>
            <div className='text-dark fw-bold text-hover-primary fs-6'>
              {cell.row.original.firstnameEn + ' ' + cell.row.original.lastnameEn}
            </div>
            <span className='text-muted fw-semibold text-muted d-block fs-7'>
              {cell.row.original.email}
            </span>
          </div>
        ),
      },
      {
        Header: 'Role',
        id: 'role',
        accessor: 'role',
        headerClassName: 'min-w-120px',
      },
      {
        Header: 'Department',
        id: 'dept_name',
        accessor: 'dept_name',
        headerClassName: 'min-w-120px',
        Cell: ({cell}: any) => <>{ReadMore(cell.row.original.dept_name)}</>,
      },
      {
        Header: 'Last Login',
        id: 'last_login',
        headerClassName: 'min-w-120px',
        Cell: ({cell}: any) => <span>{CoverTime4(cell.row.original.last_login)}</span>,
      },
      {
        Header: 'Status',
        id: 'status',
        accessor: 'status',
        headerClassName: 'min-w-50px',
        Cell: ({cell}: any) => (
          <>
            {cell.row.original.status == 'approve' ? (
              <div className='badge badge-light-success fw-bold'>Approve</div>
            ) : (
              <div className='badge badge-light-danger fw-bold'>Disapproved</div>
            )}
          </>
        ),
      },
      {
        Header: 'Joined Date',
        id: 'created_at',
        headerClassName: 'min-w-120px',
        Cell: ({cell}: any) => <span>{CoverTime4(cell.row.original.created_at)}</span>,
      },
      {
        Header: 'Last Update',
        id: 'updated',
        headerClassName: 'min-w-150px',
        Cell: ({cell}: any) => (
          <div className='d-flex justify-content-center flex-column'>
            <div className='fw-bold'>{ReturnNull(cell.row.original.updated_by)}</div>
            <span className='text-muted fw-semibold text-muted d-block fs-7'>
              {CoverTime4(cell.row.original.updated_at)}
            </span>
          </div>
        ),
      },
      {
        Header: 'Action',
        id: 'action',
        headerClassName: 'text-end',
        Cell: ({cell}: any) => (
          <div className='d-flex justify-content-end flex-shrink-0'>
            <UserEdit data={cell.row.original} getData={getData} />
          </div>
        ),
      },
    ],
    []
  )

  return (
    <>
      <TablePagination
        columns={columns}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        exportData={<UserExportData />}
        url={''}
        type={'users'}
      />
    </>
  )
}

const UserIndex = () => {
  const breadcrumbs = [
    {
      title: 'Home',
      path: '/',
      isActive: false,
    },
    {
      title: '',
      path: '',
      isActive: false,
      isSeparator: true,
    },
  ]
  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>User List</PageTitle>
      <UserForm />
    </>
  )
}

export default UserIndex
