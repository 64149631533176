import axios from 'axios'
import {BranchModel} from '../models/BranchModel'

const API_URL = process.env.REACT_APP_API_URL

export const BRANCH = `${API_URL}/branch`
export const BRANCH_FIND = `${API_URL}/branch/find`
export const BRANCH_OPTION = `${API_URL}/branch-option`

export function BranchList(limit: number, skip: number, search: any) {
  return axios.get<BranchModel>(BRANCH + `/${limit}&${skip}&${search || null}`)
}

export function BranchSave(title: string, description?: any, active?: number) {
  return axios.post(BRANCH, {
    title,
    description,
    active,
  })
}

export function BranchFind(id: string) {
  return axios.get<BranchModel>(BRANCH_FIND + `/${id}`)
}

export function BranchUpdate(id: number, title: string, description?: any, active?: number) {
  return axios.put(BRANCH, {
    id,
    title,
    description,
    active,
  })
}

export function BranchDel(id: number) {
  return axios.delete(BRANCH + `/${id}`)
}

export function BranchOption() {
  return axios.get(BRANCH_OPTION)
}
