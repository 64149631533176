import {useState, useMemo, useRef, useCallback, useEffect} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {RoleList} from '../../../modules/api/requests/RoleRequest'
import {TablePagination} from '../../../modules/widgets/table/TablePagination'
import * as PATH from '../../../modules/path/Path'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import {RoleExportData} from './export'
import {ReturnNull} from '../../../modules/widgets/func/ReturnNull'
import {CoverTime4} from '../../../modules/widgets/func/CoverTime'
import {useCurrentPage} from '../HistoryPage'

const RoleForm = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const fetchIdRef = useRef(0)
  const [index, setIndex] = useState(0)
  const [size, setSize] = useState(0)
  const [filters, setFilters] = useState('')

  const {currentPage, setCurrentPage, currentPages} = useCurrentPage()

  const fetchAPI = async ({limit, skip, search}: any) => {
    try {
      let lt = limit || 10
      let sk = skip || 0
      let sr: any = encodeURIComponent(JSON.stringify(search || null))
      const res: any = await RoleList(lt, sk, sr)
      let data: any = res.data.data
      let count: number = res.data.count
      setData(data)
      setPageCount(Math.ceil(count / limit))
      setLoading(false)
    } catch (err) {
      console.log('Error while fecth')
    }
  }

  const fetchData = useCallback(({pageSize, pageIndex, filter}: any) => {
    const fetchId = ++fetchIdRef.current
    setLoading(true)
    setIndex(pageIndex)
    setSize(pageSize)
    setFilters(filter)
    if (fetchId == fetchIdRef.current) {
      if (currentPages) {
        let page = currentPages?.page
        let cIndex = currentPages?.index
        let cSize = currentPages?.size
        let cFilter = currentPages?.filter
        let cStatus = currentPages?.status
        if (page === 'roles') {
          if (cStatus == false) {
            setIndex(cIndex)
            setSize(cSize)
            setFilters(cFilter)
            currentPages.status = true
            fetchAPI({limit: cSize, skip: cSize * cIndex, search: cFilter})
          } else {
            currentPages.page = 'roles'
            currentPages.size = pageSize
            currentPages.index = pageIndex
            currentPages.filter = filter
            currentPages.status = true
            fetchAPI({limit: pageSize, skip: pageSize * pageIndex, search: filter})
          }
        } else {
          currentPages.page = 'roles'
          currentPages.size = pageSize
          currentPages.index = pageIndex
          currentPages.filter = filter
          currentPages.status = true
          fetchAPI({limit: pageSize, skip: pageSize * pageIndex, search: filter})
        }
      } else {
        fetchAPI({limit: pageSize, skip: pageSize * pageIndex, search: filter})
      }
    }
  }, [])

  const getData = () => {
    fetchAPI({limit: size || 10, skip: size * index || 0, search: filters || ''})
  }

  useEffect(() => {}, [size, index, filters])

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        id: 'index',
        headerClassName: 'min-w-50px',
        accessor: (_row: any, i: number) => {
          if(currentPages){
            if(currentPages.page == 'roles'){
              return i + 1 + currentPages.size * currentPages.index
            }
          }
          return i + 1 + size * index
        },
      },
      {
        Header: 'Role Name',
        accessor: 'name',
      },
      {
        Header: 'Last Update',
        id: 'updated',
        headerClassName: 'min-w-150px',
        Cell: ({cell}: any) => (
          <div className='d-flex justify-content-center flex-column'>
            <div className='fw-bold'>{ReturnNull(cell.row.original.updated_by)}</div>
            <span className='text-muted fw-semibold text-muted d-block fs-7'>
              {CoverTime4(cell.row.original.updated_at)}
            </span>
          </div>
        ),
      },
      {
        Header: 'Action',
        accessor: 'action',
        headerClassName: 'text-end',
        Cell: ({cell}: any) => (
          <div className='d-flex justify-content-end flex-shrink-0'>
            <Link
              to={PATH.ROLE_EDIT + cell.row.original.id}
              className='btn btn-icon btn-light-primary  btn-sm me-2'
            >
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
            </Link>
          </div>
        ),
      },
    ],
    []
  )

  useEffect(() => {}, [size, index, filters])

  return (
    <TablePagination
      columns={columns}
      data={data}
      fetchData={fetchData}
      loading={loading}
      pageCount={pageCount}
      exportData={<RoleExportData />}
      url={PATH.ROLE_CREATE}
      type={'roles'}
    />
  )
}

const breadcrumbs = [
  {
    title: 'Home',
    path: '/',
    isActive: false,
  },
  {
    title: '',
    path: '',
    isActive: false,
    isSeparator: true,
  },
]

const Role = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Role</PageTitle>
      <RoleForm />
    </>
  )
}

export default Role
