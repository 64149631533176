import {useState, useEffect} from 'react'
import {Modal, Button, Form} from 'react-bootstrap'
import Select from 'react-select'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import {ItemFind, ItemUpdate} from '../../../api/requests/ItemRequest'
import {ItemGroupOption} from '../../../api/requests/ItemGroupRequest'
import {UnitOption} from '../../../api/requests/UnitRequest'
import {QSNewItem} from '../../../api/requests/QuotationRequest'
import Swal from 'sweetalert2'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {customStyles} from '../../Styles/ReactSelect'

const NewItem = ({data, clearData}: any) => {
  const [show, setShow] = useState(false)
  const [groups, setGroup] = useState([])
  const [units, setUnit] = useState([])
  const [checked, setChecked] = useState(false)

  const obj = {
    id: null,
    title: '',
    price: 0,
    qty: 0,
    item_group_id: '',
    unit_id: '',
    description: '',
    active: 0,
  }

  const [item, setItem] = useState(obj)

  const qsid: any = data.id || null

  const handleClose = () => {
    setItem(obj)
    clearData()
    setShow(false)
  }

  const handleShow = () => {
    getGroup()
    getUnit()
    getItem()
    setShow(true)
  }

  const getItem = async () => {
    let id: any = data.item_id
    if (id != null) {
      const res: any = await ItemFind(id)
      if (res.data.status == true) {
        setItem(res.data.data)
        if (res.data.data.active === 1) {
          setChecked(true)
        } else {
          setChecked(false)
        }
      }
    } else if (data.item_other != null) {
      setItem({
        ...item,
        title: data.item_other,
        item_group_id: data.item_group_id,
      })
    }
  }

  const getGroup = async () => {
    let options: any = []
    const res: any = await ItemGroupOption()
    if (res.data.data.length > 0) {
      res.data.data.map((val: any) => {
        options.push({value: val.id, label: val.title})
      })
      setGroup(options)
    }
  }

  const getUnit = async () => {
    let options: any = []
    const res: any = await UnitOption()
    if (res.data.data.length > 0) {
      res.data.data.map((val: any) => {
        options.push({value: val.id, label: val.title})
      })
      setUnit(options)
    }
  }

  const setActive = async (ac: any) => {
    if (ac == true) {
      formik.setFieldValue('active', 1)
    } else if (ac == false) {
      formik.setFieldValue('active', 0)
    }
    setChecked(!checked)
  }

  const numberOnly = (event: any) => {
    let input = event.target.value
    event.target.value = input
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*?)\..*/g, '$1')
      .replace(/^0[^.]/, '0')
  }

  const IsEmty = () => {
    if (
      !formik.values.item_name ||
      !formik.values.price ||
      !formik.values.item_group ||
      !formik.values.unit
    ) {
      Swal.fire({
        title: 'Please complete the information.',
        icon: 'error',
        confirmButtonColor: '#3085d6',
        heightAuto: false,
        returnFocus: false,
      })
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: item.id,
      item_name: item.title,
      price: item.price,
      qty: item.qty,
      item_group: item.item_group_id,
      unit: item.unit_id,
      description: item.description,
      active: item.active,
    },
    validationSchema: Yup.object({
      item_name: Yup.string()
        .max(255, 'Must be 255 characters or less')
        .required('item name is required'),
      price: Yup.number().required('price is required'),
      qty: Yup.number().required('qty is required'),
      item_group: Yup.number().required('item group is required'),
      unit: Yup.number().required('unit is required'),
    }),
    onSubmit: async (values) => {
      Swal.fire({
        title: 'Are you confirm to update?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        heightAuto: false,
        returnFocus: false,
        showLoaderOnConfirm: true,
        preConfirm: (e) => {
          return new Promise(async function (resolve) {
            if (qsid != null) {
              await QSNewItem(
                qsid,
                values.id,
                values.item_name,
                values.price,
                values.qty,
                values.item_group,
                values.unit,
                values.description,
                values.active
              )
                .then((res) => {
                  if (res.data.status == true) {
                    Swal.fire({
                      title: 'Successful save!',
                      icon: 'success',
                      showConfirmButton: false,
                      heightAuto: false,
                      timer: 1500,
                      returnFocus: false,
                    }).then(() => {
                      handleClose()
                    })
                  }
                })
                .catch((err) => {
                  Swal.fire({
                    title: 'Failed to save',
                    icon: 'error',
                    heightAuto: false,
                    returnFocus: false,
                  })
                })
            }
          })
        },
      })
    },
  })

  return (
    <>
      <button className='btn btn-light-primary btn-sm' onClick={handleShow}>
        <FontAwesomeIcon icon={faPlus} className='fa-fw' />
      </button>
      <Modal show={show} onHide={handleClose} className='modal-xl' centered>
        <Modal.Header closeButton>
          <Modal.Title>{data.item_other != null ? 'New Item' : 'Edit Item'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <div className='form-group mb-4'>
              <label className='s-5 fw-bold required mb-2' htmlFor='item_name'>
                Item (Part/Dwg no.)
              </label>
              <input
                type='text'
                id='item_name'
                name='item_name'
                className={'form-control form-control-sm'}
                value={formik.values.item_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.item_name && formik.errors.item_name ? (
                <div className='text-danger'>{formik.errors.item_name}</div>
              ) : null}
            </div>
            <div className='row'>
              <div className='col-md-4'>
                <div className='form-group mb-4'>
                  <label className='s-5 fw-bold required mb-2' htmlFor='price'>
                    Price
                  </label>
                  <input
                    type='text'
                    id='price'
                    name='price'
                    onInput={(e) => {
                      numberOnly(e)
                    }}
                    value={formik.values.price}
                    className={'form-control form-control-sm'}
                    onChange={(e) => {
                      let input: any = e.target.value
                      formik.setFieldValue('price', input)
                    }}
                    onBlur={(e) => {
                      let input: any = e.target.value || 0
                      if (input % 1 == 0) {
                        formik.setFieldValue('price', input)
                      } else {
                        formik.setFieldValue('price', parseFloat(input).toFixed(3))
                      }
                    }}
                  />
                  {formik.touched.price && formik.errors.price ? (
                    <div className='text-danger'>{formik.errors.price}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-4'>
                <div className='form-group mb-4'>
                  <label className='fw-bold required mb-2' htmlFor='item_group'>
                    Item Group
                  </label>
                  <Select
                    options={groups}
                    onChange={(option: any) => formik.setFieldValue('item_group', option.value)}
                    placeholder={'Please Select Item Group'}
                    value={
                      groups
                        ? groups.find((option: any) => option.value === formik.values.item_group)
                        : null
                    }
                    styles={customStyles}
                  />
                  {formik.touched.item_group && formik.errors.item_group ? (
                    <div className='text-danger'>{formik.errors.item_group}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-4'>
                <div className='form-group mb-4'>
                  <label className='fw-bold required mb-2' htmlFor='unit'>
                    Unit
                  </label>
                  <Select
                    options={units}
                    onChange={(option: any) => formik.setFieldValue('unit', option.value)}
                    placeholder={'Please Select Unit'}
                    value={
                      units ? units.find((option: any) => option.value === formik.values.unit) : ''
                    }
                    styles={customStyles}
                  />
                  {formik.touched.unit && formik.errors.unit ? (
                    <div className='text-danger'>{formik.errors.unit}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-12'>
                <div className='form-group mb-4'>
                  <label className='s-5 fw-bold mb-2' htmlFor='description'>
                    Description (EN Name/Brand/Model/Color/Size/Other/TH Name)
                  </label>
                  <textarea
                    name='description'
                    id='description'
                    className={'form-control form-control-sm '}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  ></textarea>
                  {formik.touched.description && formik.errors.description ? (
                    <div className='text-danger'>{formik.errors.description}</div>
                  ) : null}
                </div>
              </div>
              <div className='col md-12'>
                <div className='form-group mb-4'>
                  <label htmlFor='active' className='fw-bold mb-2'>
                    Active
                  </label>
                  <div className='mt-2'>
                    <div className='form-check form-switch'>
                      <input
                        className='form-check-input'
                        checked={checked}
                        type='checkbox'
                        id='active'
                        name='active'
                        value={formik.values.active}
                        onChange={(e) => {
                          setActive(e.target.checked)
                        }}
                      />
                    </div>
                  </div>
                  {formik.touched.active && formik.errors.active ? (
                    <div className='text-danger'>{formik.errors.active}</div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className='text-end mt-4'>
              <Button variant='primary' type='submit' className='me-2' onClick={IsEmty}>
                Save
              </Button>
              <Button variant='secondary' onClick={handleClose}>
                Close
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export {NewItem}
