import {useState, useEffect} from 'react'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../../_metronic/helpers'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faPlus,
  faFileCircleXmark,
  faUpRightAndDownLeftFromCenter,
  faDownLeftAndUpRightToCenter,
} from '@fortawesome/free-solid-svg-icons'
import {GroupTable} from './GroupTable'
import {AddSupplier} from './AddSupplier'
import {QSVerifyFind} from '../../../api/requests/QuotationRequest'
import {ReturnNull} from '../../func/ReturnNull'
import {OpenFile} from '../../func/OpenFile'
import Select from 'react-select'
import {SUPOption} from '../../../api/requests/SupplierRequest'
import {DCOption} from '../../../api/requests/DCRequest'
import Swal from 'sweetalert2'
import {
  UploadSUP,
  QSVerifyUpdate,
  QSVerifyFinished,
  QSVerifyRemoveFormGroup,
} from '../../../api/requests/QuotationRequest'
import {customStyles} from '../../Styles/ReactSelect'
import {BGClose} from '../../func/BackgroundLoading'

const VerifyGroup = ({id, resetData, setDisabled}: any) => {
  const [show, setShow] = useState(false)
  const [fullscreen, setFullscreen] = useState<any>('')
  const [columns, setColumn] = useState([])
  const [data, setData] = useState([])
  const [sup, setSup] = useState([])
  const [dc, setDc] = useState([])
  const [qs, setQS] = useState([])
  const [group, setGroup] = useState([])
  const [jobDoc, setJobDoc] = useState<any>([])
  const [newjobdoc, setNewJobDoc] = useState([])
  const [dataRemove, setDataRemove] = useState([])
  const [status, setStatus] = useState('')
  const [jobitem, setJobItem] = useState({
    id: null,
    job_id: id,
    gp_supplier_choose_id_one: 0,
    gp_supplier_other_one: '',
    gp_supplier_choose_id_sec: 0,
    gp_supplier_other_sec: '',
    gp_supplier_choose_id_thr: 0,
    gp_supplier_other_thr: '',
    gp_supplier_choose_file_one: '',
    gp_supplier_choose_file_sec: '',
    gp_supplier_choose_file_thr: '',
  })

  const job_id: number = id || 0
  const [boxsup1, setBoxSup1] = useState(false)
  const [boxsup2, setBoxSup2] = useState(false)
  const [boxsup3, setBoxSup3] = useState(false)
  const [checkedRemove, setCheckedRemove] = useState(false)

  const handleClose = () => {
    setShow(false)
    clearData()
  }

  const handleShow = () => {
    clearData()
    getData()
    setShow(true)
  }

  const openFull = () => {
    if (fullscreen === true) {
      setFullscreen('')
    } else {
      setFullscreen(true)
    }
  }

  const clearData = () => {
    if (checkedRemove) {
      resetData()
    }
    setData([])
    setQS([])
    setDataRemove([])
    setGroup([])
  }

  const getData = async () => {
    const res: any = await QSVerifyFind(job_id)
    setData(res.data.data.results)
    setJobDoc(res.data.data.document_concerneds)
    setNewJobDoc(res.data.data.document_concerneds)
    let newgroup: any = []
    let newitem: any = res.data.data.jobs
    let groups: any = res.data.data.results
    groups.map((val: any) => {
      newgroup.push(val.id)
      setStatus(val.status)
    })
    if (groups.length <= 0) {
      handleClose()
    }
    if (Object.keys(newitem).length !== 0) {
      setJobItem(newitem)
      if (newitem.gp_supplier_other_one != null) {
        setBoxSup1(true)
      }
      if (newitem.gp_supplier_other_sec != null) {
        setBoxSup2(true)
      }
      if (newitem.gp_supplier_other_thr != null) {
        setBoxSup3(true)
      }
    }
    setGroup(newgroup)
    setDataRemove([])
  }

  const getSup = async () => {
    let options: any = []
    const res: any = await SUPOption()
    if (res.data.data.length > 0) {
      res.data.data.map((val: any) => {
        options.push({value: val.id, label: val.title})
      })
      setSup(options)
    }
  }

  const getDC = async () => {
    let options: any = []
    const res: any = await DCOption()
    if (res.data.data.length > 0) {
      res.data.data.map((val: any) => {
        options.push({value: val.id, label: val.title})
      })
      setDc(options)
    }
  }

  const getNewDocument = (options: any) => {
    setNewJobDoc(options)
  }

  const getNewDataFile = (type: string, name: string) => {
    if (jobitem) {
      if (type == 'sup_file_1') {
        setJobItem((jobitem) => ({...jobitem, gp_supplier_choose_file_one: name}))
      } else if (type == 'sup_file_2') {
        setJobItem((jobitem) => ({...jobitem, gp_supplier_choose_file_sec: name}))
      } else if (type == 'sup_file_3') {
        setJobItem((jobitem) => ({...jobitem, gp_supplier_choose_file_thr: name}))
      }
    }
  }

  const getNewData = (type: string, id: number) => {
    if (jobitem) {
      if (type == 'sup_1') {
        setJobItem((jobitem) => ({...jobitem, gp_supplier_choose_id_one: id}))
      } else if (type == 'sup_2') {
        setJobItem((jobitem) => ({...jobitem, gp_supplier_choose_id_sec: id}))
      } else if (type == 'sup_3') {
        setJobItem((jobitem) => ({...jobitem, gp_supplier_choose_id_thr: id}))
      }
    }
  }

  const dialogFile = (type: string) => {
    let box = document.getElementById(`bg_load`) as HTMLInputElement
    let input = document.createElement('input')
    input.type = 'file'
    box.style.display = 'block'
    input.onchange = async (e: any) => {
      let file = e.target.files[0]
      let size: number = file.size || 0
      box.style.display = 'none'
      if (size <= 2000000) {
        Swal.fire({
          title: 'Are you confirm to upload?',
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          showLoaderOnConfirm: true,
          heightAuto: false,
          returnFocus: false,
          preConfirm: (e) => {
            return new Promise(async function (resolve) {
              let form = new FormData()
              form.append('file', file, file.name)
              await UploadSUP(form)
                .then((res) => {
                  if (res.data.status == true) {
                    Swal.fire({
                      title: 'Successful upload!',
                      icon: 'success',
                      showConfirmButton: false,
                      heightAuto: false,
                      timer: 1500,
                      returnFocus: false,
                    })
                    getNewDataFile(type, res.data.message)
                  }
                })
                .catch((err) => {
                  Swal.fire({
                    title: 'Failed to save',
                    icon: 'error',
                    heightAuto: false,
                    returnFocus: false,
                  })
                })
            })
          },
        })
      } else {
        Swal.fire({
          title: 'File size not more than 2 MB.',
          icon: 'error',
          heightAuto: false,
          returnFocus: false,
        })
      }
    }
    input.click()
  }

  const selectAll = (bool: boolean) => {
    const ele: any = document.getElementsByName('chk')
    for (let i = 0; i < ele.length; i++) {
      if (bool === true) {
        ele[i].checked = true
      } else {
        ele[i].checked = false
      }
    }
    selectAllId(bool)
  }

  const selectAllId = (bool: boolean) => {
    let selects: any = []
    if (bool === true) {
      data.map((val: any) => {
        selects.push(val.id)
      })
    }
    setDataRemove(selects)
  }

  const getItem = (id: number, bool: boolean) => {
    let selects: any = []
    selects = dataRemove
    if (bool == true) {
      if (selects.includes(id) == false) {
        selects.push(id)
        setDataRemove(selects)
      }
    } else {
      if (selects.includes(id) == true) {
        let index = selects.findIndex((val: number) => val == id)
        if (index > -1) {
          selects.splice(index, 1)
          setDataRemove(selects)
        }
      }
    }
  }

  const submit = () => {
    let dc_format: any = []

    const op: any = newjobdoc.map((val: any) => val.value)
    const removedoc: any = jobDoc
      .filter((doc: any) => {
        if (op.includes(doc.document_concerned_id) == false) {
          return doc
        }
      })
      .map((doc: any) => doc.id)

    newjobdoc.map((val: any) => {
      let obj: any = {}
      obj.id = null
      obj.job_id = job_id
      obj.document_concerned_id = val.value
      jobDoc.map((doc: any) => {
        if (val.value == doc.document_concerned_id) {
          obj.id = doc.id
        }
      })
      dc_format.push(obj)
    })

    const newobj: any = {
      document_concerneds: {
        new: dc_format,
        remove: removedoc,
      },
      jobs: jobitem,
    }

    if (newobj !== null && typeof newobj !== 'undefined' && Object.keys(newobj).length > 0) {
      Swal.fire({
        title: 'Are you confirm to save?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        heightAuto: false,
        returnFocus: false,
        showLoaderOnConfirm: true,
        preConfirm: (e) => {
          return new Promise(async function (resolve) {
            await QSVerifyUpdate(newobj)
              .then((res) => {
                if (res.data.status == true) {
                  Swal.fire({
                    title: 'Successful created!',
                    icon: 'success',
                    showConfirmButton: false,
                    heightAuto: false,
                    timer: 1500,
                    returnFocus: false,
                  }).then(() => {
                    handleClose()
                    resetData()
                  })
                }
              })
              .catch((err) => {
                Swal.fire({
                  title: 'Failed to save',
                  icon: 'error',
                  heightAuto: false,
                  returnFocus: false,
                })
              })
          })
        },
      })
    } else {
      Swal.fire({
        title: 'Data not found.',
        icon: 'error',
        heightAuto: false,
        returnFocus: false,
      })
    }
  }

  const finished = () => {
    if (group.length > 0) {
      Swal.fire({
        title: 'Are you confirm to finished?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        heightAuto: false,
        returnFocus: false,
        showLoaderOnConfirm: true,
        preConfirm: (e) => {
          return new Promise(async function (resolve) {
            await QSVerifyFinished(group)
              .then((res) => {
                if (res.data.status == true) {
                  Swal.fire({
                    title: 'Successful finished!',
                    icon: 'success',
                    showConfirmButton: false,
                    heightAuto: false,
                    timer: 1500,
                    returnFocus: false,
                  }).then(() => {
                    handleClose()
                    resetData()
                  })
                }
              })
              .catch((err) => {
                Swal.fire({
                  title: 'Failed to save',
                  icon: 'error',
                  heightAuto: false,
                  returnFocus: false,
                })
              })
          })
        },
      })
    } else {
      Swal.fire({
        title: 'Group data not found',
        icon: 'error',
        heightAuto: false,
        returnFocus: false,
      })
    }
  }

  const removeData = () => {
    if (dataRemove.length > 0) {
      Swal.fire({
        title: 'Are you confirm to remove from group',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        heightAuto: false,
        returnFocus: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await QSVerifyRemoveFormGroup(dataRemove)
            .then((res) => {
              if (res.data.status == true) {
                Swal.fire({
                  title: 'Successful remove from group!',
                  icon: 'success',
                  showConfirmButton: false,
                  heightAuto: false,
                  timer: 1500,
                  returnFocus: false,
                }).then(() => {
                  getData()
                  setCheckedRemove(true)
                })
              }
            })
            .catch((err) => {
              Swal.fire({
                title: 'Failed to save',
                icon: 'error',
                heightAuto: false,
                returnFocus: false,
              })
            })
        }
      })
    } else {
      Swal.fire({
        title: 'No select information found.',
        text: 'Please select information first.',
        icon: 'error',
        heightAuto: false,
        returnFocus: false,
      })
    }
  }

  let table: any = [
    {
      Header: () => {
        return (
          <>
            <div className='d-flex justify-content-center flex-shrink-0'>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  onChange={(e: any) => {
                    selectAll(e.target.checked)
                  }}
                />
              </div>
            </div>
          </>
        )
      },
      id: 'select_row',
      headerClassName: 'bg-light-success min-w-50px',
      Cell: ({cell}: any) => (
        <>
          <div className='d-flex justify-content-center flex-shrink-0'>
            <div className='form-check'>
              <input
                className='form-check-input'
                name='chk'
                type='checkbox'
                onChange={(e) => {
                  getItem(cell.row.original.id, e.target.checked)
                }}
              />
            </div>
          </div>
        </>
      ),
    },
    {
      Header: 'Job No.',
      headerClassName: 'bg-light-success min-w-100px',
      Cell: ({cell}: any) => <>{ReturnNull(cell.row.original.job_number)}</>,
    },
    {
      Header: 'Group',
      headerClassName: 'bg-light-success min-w-100px',
      Cell: ({cell}: any) => <>{ReturnNull(cell.row.original.item_group_title)}</>,
    },
    {
      Header: 'Item',
      headerClassName: 'bg-light-success min-w-100px',
      Cell: ({cell}: any) => (
        <>{ReturnNull(cell.row.original.item_title || cell.row.original.item_other)}</>
      ),
    },
    {
      Header: 'Picture(FA)',
      headerClassName: 'bg-light-success min-w-100px',
      Cell: ({cell}: any) => <>{OpenFile(cell.row.original.file_url_pic, 'pic')}</>,
    },
    {
      Header: 'Drawing(FA)',
      headerClassName: 'bg-light-success min-w-100px',
      Cell: ({cell}: any) => <>{OpenFile(cell.row.original.file_url_draw, 'draw')}</>,
    },
  ]

  const getColumn = () => {
    getSup()
    getDC()
    setColumn(table)
  }

  useEffect(() => {
    getColumn()
  }, [jobitem, status, qs])

  return (
    <>
      <button
        className='btn btn-icon btn-sm btn-light-primary'
        onClick={() => {
          handleShow()
        }}
        disabled={setDisabled}
      >
        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
      </button>
      <Modal
        show={show}
        onHide={handleClose}
        size='xl'
        fullscreen={fullscreen}
        className='p-0'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Group&nbsp;Quotation</Modal.Title>
          <button
            className='btn btn-light-white btn-sm'
            onClick={() => {
              openFull()
            }}
          >
            {fullscreen != true ? (
              <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} className='fa-fw' />
            ) : (
              <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} className='fa-fw' />
            )}
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-md-7 border-right'>
              <div className='text-end mb-2'>
                <button
                  className='btn btn-light-danger btn-sm'
                  title='remove'
                  onClick={() => {
                    removeData()
                  }}
                >
                  <FontAwesomeIcon icon={faFileCircleXmark} className='fa-fw' />
                  &nbsp;Remove
                </button>
              </div>
              <GroupTable columns={columns} data={data} />
            </div>
            <div className='col-md-5'>
              <div className='d-flex justify-content-between mb-2'>
                <h5>Quotation Attachment</h5>
                <AddSupplier getSup={getSup} />
              </div>
              <div className='table-responsive'>
                <table className='table table-sm text-center'>
                  <thead className='border-bottom border-gray-200 text-gray-600 fw-bold bg-light bg-opacity-75'>
                    <tr>
                      <th colSpan={1} className='min-w-200px'>
                        Supplier name
                      </th>
                      <th colSpan={1} className='min-w-50px'>
                        Manual
                      </th>
                      <th colSpan={1} className='min-w-50px'>
                        Attach File
                      </th>
                      <th colSpan={1} className='min-w-50px'>
                        View
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='align-middle'>
                        {boxsup1 ? (
                          <input
                            type='text'
                            className='form-control form-control-sm border border-danger'
                            placeholder='Please fill out'
                            maxLength={255}
                            value={jobitem.gp_supplier_other_one}
                            onChange={(e) => {
                              setJobItem((jobitem: any) => ({
                                ...jobitem,
                                gp_supplier_other_one: e.target.value,
                              }))
                            }}
                          />
                        ) : (
                          <Select
                            className='text-start'
                            menuPortalTarget={document.body}
                            options={sup}
                            styles={customStyles}
                            value={
                              sup
                                ? sup.find(
                                    (option: any) =>
                                      jobitem.gp_supplier_choose_id_one == option.value
                                  )
                                : ''
                            }
                            onChange={(option: any) => {
                              getNewData('sup_1', option.value)
                            }}
                          />
                        )}
                      </td>
                      <td className='align-middle'>
                        <input
                          type='checkbox'
                          className='form-check-input'
                          checked={boxsup1}
                          onChange={(e) => {
                            setBoxSup1(e.target.checked)
                            setJobItem((jobitem: any) => ({
                              ...jobitem,
                              gp_supplier_choose_id_one: null,
                              gp_supplier_other_one: null,
                            }))
                          }}
                        />
                      </td>
                      <td className='align-middle'>
                        <button
                          className='btn btn-secondary btn-sm text-primary'
                          onClick={() => {
                            dialogFile('sup_file_1')
                          }}
                          onFocusCapture={BGClose}
                        >
                          Upload
                        </button>
                      </td>
                      <td className='align-middle'>
                        {jobitem.gp_supplier_choose_file_one != ''
                          ? OpenFile(jobitem.gp_supplier_choose_file_one, 'suppliers')
                          : '-'}
                      </td>
                    </tr>
                    <tr>
                      <td className='align-middle'>
                        {boxsup2 ? (
                          <input
                            type='text'
                            className='form-control form-control-sm border border-danger'
                            placeholder='Please fill out'
                            maxLength={255}
                            value={jobitem.gp_supplier_other_sec}
                            onChange={(e) => {
                              setJobItem((jobitem: any) => ({
                                ...jobitem,
                                gp_supplier_other_sec: e.target.value,
                              }))
                            }}
                          />
                        ) : (
                          <Select
                            className='text-start'
                            menuPortalTarget={document.body}
                            options={sup}
                            styles={customStyles}
                            value={
                              sup
                                ? sup.find(
                                    (option: any) =>
                                      jobitem.gp_supplier_choose_id_sec == option.value
                                  )
                                : ''
                            }
                            onChange={(option: any) => {
                              getNewData('sup_2', option.value)
                            }}
                          />
                        )}
                      </td>
                      <td className='align-middle'>
                        <input
                          type='checkbox'
                          className='form-check-input'
                          checked={boxsup2}
                          onChange={(e) => {
                            setBoxSup2(e.target.checked)
                            setJobItem((jobitem: any) => ({
                              ...jobitem,
                              gp_supplier_choose_id_sec: null,
                              gp_supplier_other_sec: null,
                            }))
                          }}
                        />
                      </td>
                      <td className='align-middle'>
                        <button
                          className='btn btn-secondary btn-sm text-primary'
                          onClick={() => {
                            dialogFile('sup_file_2')
                          }}
                          onFocusCapture={BGClose}
                        >
                          Upload
                        </button>
                      </td>
                      <td className='align-middle'>
                        {jobitem.gp_supplier_choose_file_sec != ''
                          ? OpenFile(jobitem.gp_supplier_choose_file_sec, 'suppliers')
                          : '-'}
                      </td>
                    </tr>
                    <tr>
                      <td className='align-middle'>
                        {boxsup3 ? (
                          <input
                            type='text'
                            className='form-control form-control-sm border border-danger'
                            placeholder='Please fill out'
                            maxLength={255}
                            value={jobitem.gp_supplier_other_thr}
                            onChange={(e) => {
                              setJobItem((jobitem: any) => ({
                                ...jobitem,
                                gp_supplier_other_thr: e.target.value,
                              }))
                            }}
                          />
                        ) : (
                          <Select
                            className='text-start'
                            menuPortalTarget={document.body}
                            options={sup}
                            styles={customStyles}
                            value={
                              sup
                                ? sup.find(
                                    (option: any) =>
                                      jobitem.gp_supplier_choose_id_thr == option.value
                                  )
                                : ''
                            }
                            onChange={(option: any) => {
                              getNewData('sup_3', option.value)
                            }}
                          />
                        )}
                      </td>
                      <td className='align-middle'>
                        <input
                          type='checkbox'
                          className='form-check-input'
                          checked={boxsup3}
                          onChange={(e) => {
                            setBoxSup3(e.target.checked)
                            setJobItem((jobitem: any) => ({
                              ...jobitem,
                              gp_supplier_choose_id_thr: null,
                              gp_supplier_other_thr: null,
                            }))
                          }}
                        />
                      </td>
                      <td className='align-middle'>
                        <button
                          className='btn btn-secondary btn-sm text-primary'
                          onClick={() => {
                            dialogFile('sup_file_3')
                          }}
                          onFocusCapture={BGClose}
                        >
                          Upload
                        </button>
                      </td>
                      <td className='align-middle'>
                        {jobitem.gp_supplier_choose_file_thr != ''
                          ? OpenFile(jobitem.gp_supplier_choose_file_thr, 'suppliers')
                          : '-'}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <hr />
          <div className='row mb-4'>
            <div className='col-md-4'>
              <div className='mt-2'>
                <div className='mb-2 fw-bold'>Document Concerned</div>
                <Select
                  options={dc}
                  isMulti
                  menuPortalTarget={document.body}
                  onChange={(option: any) => {
                    getNewDocument(option)
                  }}
                  value={newjobdoc.length > 0 ? newjobdoc : ''}
                  styles={customStyles}
                />
              </div>
            </div>
          </div>
          <div className='text-center'>
            <button className='btn btn-light-primary me-2' onClick={submit}>
              Save
            </button>
            <button className='btn btn-light-danger me-2' onClick={handleClose}>
              Cancel
            </button>
            <button
              className='btn btn-light-success'
              onClick={() => {
                finished()
              }}
            >
              Finished
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export {VerifyGroup}
