import {PageTitle} from '../../../_metronic/layout/core'
import {LogList, LogDel} from '../../modules/api/requests/LogRequest'
import {UserList} from '../../modules/api/requests/UserRequest'
import {useState, useEffect, useMemo, useRef, useCallback} from 'react'
import Swal from 'sweetalert2'
import {TablePagination} from '../../modules/widgets/table/TablePagination'
import {CoverTime4} from '../../modules/widgets/func/CoverTime'
import {ReadMore} from '../../modules/widgets/func/ReadMore'
import {KTSVG} from '../../../_metronic/helpers'
import {LogExportData} from './Export'

const LogsPage = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const fetchIdRef = useRef(0)
  let filters = ''
  let index = 0
  let size = 0

  const fetchAPI = async ({limit, skip, search}: any) => {
    try {
      let lt = limit || 10
      let sk = skip || 0
      let sr: any = encodeURIComponent(JSON.stringify(search || null))
      const res: any = await LogList(lt, sk, sr)
      let data: any = res.data.data
      let count: number = res.data.count
      setData(data)
      setPageCount(Math.ceil(count / limit))
      setLoading(false)
    } catch (err) {
      console.log('Error while fecth')
    }
  }

  const fetchData = useCallback(({pageSize, pageIndex, filter}: any) => {
    const fetchId = ++fetchIdRef.current
    setLoading(true)
    index = pageIndex
    size = pageSize
    filters = filter
    if (fetchId == fetchIdRef.current) {
      fetchAPI({limit: pageSize, skip: pageSize * pageIndex, search: filter})
    }
  }, [])

  const getData = () => {
    fetchAPI({limit: size || 10, skip: (size * index) || 0, search: filters || ''})
  }

  const del = async (id: number) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You confirm Delete',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      heightAuto: false,
      returnFocus: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await LogDel(id)
          .then((res) => {
            if (res.data.status == true) {
              Swal.fire({
                title: 'Deleted',
                text: 'Successful Deleted',
                icon: 'success',
                showConfirmButton: false,
                heightAuto: false,
                timer: 500,
                returnFocus: false,
              }).then(() => {
                getData()
              })
            }
          })
          .catch((err) => {
            Swal.fire({
              title: 'Failed to delete',
              icon: 'error',
              heightAuto: false,
              returnFocus: false,
            })
          })
      }
    })
  }

  useEffect(() => {}, [size, index])

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        id: 'index',
        headerClassName: 'min-w-50px',
        accessor: (_row: any, i: number) => i + 1 + size * index,
      },
      {
        Header: 'Event',
        accessor: 'event',
        headerClassName: 'min-w-150px',
      },
      {
        Header: 'EmID',
        accessor: 'emid',
        headerClassName: 'min-w-50px',
      },
      {
        Header: 'User',
        id: 'user_id',
        headerClassName: 'min-w-150px',
        Cell: ({cell}: any) => (
          <span>{cell.row.original.firstnameEn + ' ' + cell.row.original.lastnameEn}</span>
        ),
      },
      {
        Header: 'Time',
        id: 'time',
        headerClassName: 'min-w-100px',
        Cell: ({cell}: any) => <span>{CoverTime4(cell.row.original.created_at)}</span>,
      },
    ],
    []
  )

  return (
    <TablePagination
      columns={columns}
      data={data}
      fetchData={fetchData}
      loading={loading}
      pageCount={pageCount}
      exportData={<LogExportData />}
      url={''}
      type={'logs'}
    />
  )
}

const breadcrumbs = [
  {
    title: 'Home',
    path: '/',
    isActive: false,
  },
  {
    title: '',
    path: '',
    isActive: false,
    isSeparator: true,
  },
]

const LogWrapper = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Logs</PageTitle>
      <LogsPage />
    </>
  )
}

export {LogWrapper}
