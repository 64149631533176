const CustomItemFormat = (active: number) => {
  if (active == 1) {
    return <span className='badge badge-light-success'>allow</span>
  } else if (active == 0) {
    return <span className='badge badge-light-secondary'>not allowed</span>
  } else {
    return active
  }
}

const ConvertCustomItem = (active: number) => {
  if (active == 1) {
    return 'allow'
  } else if (active == 0) {
    return 'not allowed'
  } else {
    return active
  }
}

export {CustomItemFormat, ConvertCustomItem}
