import axios from 'axios'
import {LogModel} from '../models/LogModel'

const API_URL = process.env.REACT_APP_API_URL

export const LOG = `${API_URL}/log`

export function LogList(limit: number, skip: number, search: any) {
  return axios.get(LOG + `/${limit}&${skip}&${search}`)
}

export function LogDel(id: number) {
  return axios.delete(LOG + `/${id}`)
}