const activeFormat = (active: number) => {
  if (typeof active === 'undefined' || active == null) {
    return <span className='badge badge-light-secondary'>inactive</span>
  } else {
    if (active == 1) {
      return <span className='badge badge-light-success'>active</span>
    } else if (active == 0) {
      return <span className='badge badge-light-secondary'>inactive</span>
    } else {
      return active
    }
  }
}

const ConvertActive = (active: number) => {
  if (active == 1) {
    return 'active'
  } else if (active == 0) {
    return 'inactive'
  } else {
    return active
  }
}

export {activeFormat, ConvertActive}
