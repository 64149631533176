import axios from 'axios'
import {UserModel} from '../models/UserModel'

const API_URL = process.env.REACT_APP_API_URL

export const USER = `${API_URL}/user`
export const USER_FIND = `${API_URL}/user/find`
export const USER_LOG = `${API_URL}/user-log`

export function UserList() {
  return axios.get<UserModel>(USER)
}

export function UserFind(id: string) {
  return axios.get(USER_FIND + `/${id}`)
}

export function UserDel(id: number) {
  return axios.delete(USER + `/${id}`)
}

export function UserUpdate(id: number, status: string, dept_id: number, role_id: number) {
  return axios.put(USER, {
    id,
    status,
    dept_id,
    role_id,
  })
}

export function UserLog(limit: number, skip: number, search: any) {
  return axios.get(USER_LOG + `/${limit}&${skip}&${search || null}`)
}
