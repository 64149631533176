import axios from 'axios'
import {SupplierModel} from '../models/SupplierModel'

const API_URL = process.env.REACT_APP_API_URL

export const SUP = `${API_URL}/supplier`
export const SUP_FIND = `${API_URL}/supplier/find`
export const SUP_OPTION = `${API_URL}/supplier-option`

export function SUPList(limit: number, skip: number, search: any) {
  return axios.get<SupplierModel>(SUP + `/${limit}&${skip}&${search || null}`)
}

export function SUPSave(
  title: string,
  tel: string,
  email: string,
  fax: string,
  contact_name: string,
  address: string,
  active: number,
  bpcs_code: string,
  branch_id?: number,
  supplier_group_id?: number
) {
  return axios.post(SUP, {
    title,
    tel,
    email,
    fax,
    contact_name,
    address,
    active,
    bpcs_code,
    branch_id,
    supplier_group_id,
  })
}

export function SUPFind(id: string) {
  return axios.get<SupplierModel>(SUP_FIND + `/${id}`)
}

export function SUPUpdate(
  id: number,
  title: string,
  tel: string,
  email: string,
  fax: string,
  contact_name: string,
  address: string,
  active: number,
  bpcs_code: string,
  branch_id?: number,
  supplier_group_id?: number
) {
  return axios.put(SUP, {
    id,
    title,
    tel,
    email,
    fax,
    contact_name,
    address,
    active,
    bpcs_code,
    branch_id,
    supplier_group_id,
  })
}

export function SUPDel(id: number) {
  return axios.delete(SUP + `/${id}`)
}

export function SUPOption() {
  return axios.get(SUP_OPTION)
}
