import {useState, useEffect, useMemo, useRef} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {KTSVG} from '../../../../_metronic/helpers'
import {Modal, Button} from 'react-bootstrap'
import Swal from 'sweetalert2'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {UserLog, UserFind, UserUpdate, UserDel} from '../../../modules/api/requests/UserRequest'
import {DeptOption} from '../../../modules/api/requests/DeptRequest'
import {RoleOption} from '../../../modules/api/requests/RoleRequest'
import Select from 'react-select'
import {customStyles} from '../../../modules/widgets/Styles/ReactSelect'

const UserEdit = ({data, getData}: any) => {
  const [show, setShow] = useState(false)
  const [dept, setDept] = useState([])
  const [role, setRole] = useState([])
  const obj = {
    id: 0,
    status: '',
    dept_id: 0,
    role_id: 0,
  }
  const [newdata, setNewData] = useState(obj)
  const formButtonRef: any = useRef(null)

  const handleClose = () => {
    setNewData(obj)
    setShow(false)
  }

  const handleShow = () => {
    formButtonRef.current.blur()
    setNewData({
      ...newdata,
      id: data.id || 0,
      status: data.status || '',
      dept_id: data.dept_id || 0,
      role_id: data.role_id || 0,
    })
    getDept()
    getRole()
    setShow(true)
  }

  const statusOption = [
    {value: 'approve', label: 'Approve'},
    {value: 'disapproved', label: 'Disapproved'},
  ]

  const getDept = async () => {
    let options: any = []
    const res: any = await DeptOption()
    if (res.data.data.length > 0) {
      res.data.data.map((val: any) => {
        options.push({value: val.id, label: val.dept_name})
      })
      setDept(options)
    }
  }

  const getRole = async () => {
    let options: any = []
    const res: any = await RoleOption()
    if (res.data.data.length > 0) {
      res.data.data.map((val: any) => {
        options.push({value: val.id, label: val.name})
      })
      setRole(options)
    }
  }

  const formik = useFormik({
    initialValues: {
      id: newdata.id,
      status: newdata.status,
      dept_id: newdata.dept_id,
      role_id: newdata.role_id,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      status: Yup.string().required('status is required'),
      dept_id: Yup.number().min(1, 'department is required').required('department is required'),
      role_id: Yup.number().min(1, 'role is required').required('role is required'),
    }),
    onSubmit: async (values) => {
      Swal.fire({
        title: 'Are you confirm to update?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        heightAuto: false,
        returnFocus: false,
        showLoaderOnConfirm: true,
        preConfirm: (e) => {
          return new Promise(async function (resolve) {
            await UserUpdate(values.id, values.status, values.dept_id, values.role_id)
              .then((res) => {
                if (res.data.status == true) {
                  Swal.fire({
                    title: 'Successful updated!',
                    icon: 'success',
                    showConfirmButton: false,
                    heightAuto: false,
                    timer: 1500,
                    returnFocus: false,
                  }).then(() => {
                    getData()
                    handleClose()
                  })
                }
              })
              .catch((err) => {
                Swal.fire({
                  title: 'Failed to save',
                  icon: 'error',
                  heightAuto: false,
                  returnFocus: false,
                })
              })
          })
        },
      })
    },
  })

  return (
    <>
      <button
        ref={formButtonRef}
        className='btn btn-icon btn-light-primary  btn-sm me-2'
        onClick={handleShow}
      >
        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
      </button>
      <Modal show={show} onHide={handleClose} centered>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Edit User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='form-grouo mb-4'>
              <label htmlFor='' className='required fw-semibold fs-6 mb-2'>
                Status
              </label>
              <Select
                placeholder={'Please Select Status'}
                options={statusOption}
                value={
                  statusOption
                    ? statusOption.find((option: any) => option.value === formik.values.status)
                    : ''
                }
                onChange={(option: any) => formik.setFieldValue('status', option.value)}
                styles={customStyles}
              />
              {formik.touched.status && formik.errors.status ? (
                <div className='text-danger'>{formik.errors.status}</div>
              ) : null}
            </div>
            <div className='form-grouo mb-4'>
              <label htmlFor='' className='required fw-semibold fs-6 mb-2'>
                Department
              </label>
              <Select
                placeholder={'Please Select Department'}
                options={dept}
                value={
                  dept ? dept.find((option: any) => option.value === formik.values.dept_id) : ''
                }
                onChange={(option: any) => formik.setFieldValue('dept_id', option.value)}
                styles={customStyles}
              />
              {formik.touched.dept_id && formik.errors.dept_id ? (
                <div className='text-danger'>{formik.errors.dept_id}</div>
              ) : null}
            </div>
            <div className='form-grouo mb-4'>
              <label htmlFor='' className='required fw-semibold fs-6 mb-2'>
                Role
              </label>
              <Select
                placeholder={'Please Select Role'}
                options={role}
                value={
                  role ? role.find((option: any) => option.value === formik.values.role_id) : ''
                }
                onChange={(option: any) => formik.setFieldValue('role_id', option.value)}
                styles={customStyles}
              />
              {formik.touched.role_id && formik.errors.role_id ? (
                <div className='text-danger'>{formik.errors.role_id}</div>
              ) : null}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='light-primary' type='submit'>
              Update
            </Button>
            <Button variant='light-danger' onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export {UserEdit}
