import axios from 'axios'
import {SupplierGroupModel} from '../models/SupplierGroupModel'

const API_URL = process.env.REACT_APP_API_URL

export const SG = `${API_URL}/supplier-group`
export const SG_FIND = `${API_URL}/supplier-group/find`
export const SG_OPTION = `${API_URL}/supplierGroup-option`

export function SGList(limit: number, skip: number, search: any) {
  return axios.get<SupplierGroupModel>(SG + `/${limit}&${skip}&${search || null}`)
}

export function SGSave(title: string, description?: string, active?: number) {
  return axios.post(SG, {
    title,
    description,
    active,
  })
}

export function SGFind(id: string) {
  return axios.get<SupplierGroupModel>(SG_FIND + `/${id}`)
}

export function SGUpdate(id: number, title: string, description?: string, active?: number) {
  return axios.put(SG, {
    id,
    title,
    description,
    active,
  })
}

export function SGDel(id: number) {
  return axios.delete(SG + `/${id}`)
}

export function SGOption() {
  return axios.get(SG_OPTION)
}
