import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import * as PATH from '../../../../app/modules/path/Path'
import {useAuth} from '../../../../app/modules/auth'
import {useEffect, useState} from 'react'

export function MenuInner() {
  const {currentUser} = useAuth()
  const [qs, setQS] = useState(false)
  const [system, setSystem] = useState(false)
  const [user, setUser] = useState(false)
  const [item, setItem] = useState(false)
  const [supplier, setSupplier] = useState(false)

  let role: any = currentUser?.roles || null

  const RoleData = (role: any) => {
    let data: any = []
    if (role == null || typeof role === 'undefined' || role == '') {
      data = []
    } else {
      data = role.permissions
    }
    return data
  }

  const RoleIdData = (role: any) => {
    let data: any = null
    if (role == null || typeof role === 'undefined' || role == '') {
      data = null
    } else {
      data = role.id
    }
    return data
  }

  let permissions: any = RoleData(role)
  let role_id: any = RoleIdData(role)

  const setRole = () => {
    let quotation_group: any = ['qs_create', 'qs_pending', 'qs_verify', 'qs_report']
    let system_data_group: any = [
      'user_management',
      'role',
      'department',
      'branch',
      'supplier_group',
      'supplier',
      'item_group',
      'item',
      'unit',
      'document_concerned',
    ]
    let user_group: any = ['user_management', 'role']
    let item_group: any = ['item_group', 'item']
    let supplier_group: any = ['supplier_group', 'supplier']

    let quotation_result = quotation_group.some((i: any) => permissions.includes(i))
    let system_result = system_data_group.some((i: any) => permissions.includes(i))
    let user_result = user_group.some((i: any) => permissions.includes(i))
    let item_result = item_group.some((i: any) => permissions.includes(i))
    let supplier_result = supplier_group.some((i: any) => permissions.includes(i))

    setQS(quotation_result)
    setSystem(system_result)
    setUser(user_result)
    setItem(item_result)
    setSupplier(supplier_result)
  }

  useEffect(() => {
    setRole()
  }, [])

  return (
    <>
      {/* Dashboard */}
      <MenuItem title='Home' to={PATH.HOME} />
      {/* <MenuItem title='Layout Builder' to='/builder' /> */}

      {role != null || role_id == 1 ? (
        <>
          {/* Quotation */}
          {qs == true || role_id == 1 ? (
            <MenuInnerWithSub
              title='Quotation'
              to='/crafted'
              menuPlacement='bottom-start'
              menuTrigger='click'
            >
              {permissions.includes('qs_create') || role_id == 1 ? (
                <MenuItem
                  title='Create'
                  icon='/media/icons/duotune/files/fil005.svg'
                  to={PATH.QS_CREATE}
                />
              ) : (
                ''
              )}

              {permissions.includes('qs_pending') || role_id == 1 ? (
                <MenuItem
                  title='Pending List'
                  icon='/media/icons/duotune/files/fil003.svg'
                  to={PATH.QS_PENDING}
                />
              ) : (
                ''
              )}

              {permissions.includes('qs_verify') || role_id == 1 ? (
                <MenuItem
                  title='Verification List'
                  icon='/media/icons/duotune/files/fil025.svg'
                  to={PATH.QS_VERIFY}
                />
              ) : (
                ''
              )}

              {permissions.includes('qs_report') || role_id == 1 ? (
                <MenuItem
                  title='Report List'
                  icon='/media/icons/duotune/files/fil001.svg'
                  to={PATH.QS_LIST}
                />
              ) : (
                ''
              )}
            </MenuInnerWithSub>
          ) : (
            ''
          )}

          {/* Master Setting */}
          {system == true || role_id == 1 ? (
            <MenuInnerWithSub
              title='System data'
              to=''
              menuPlacement='bottom-start'
              menuTrigger='click'
            >
              {user == true || role_id == 1 ? (
                <MenuInnerWithSub
                  title='User Management'
                  icon='/media/icons/duotune/general/gen049.svg'
                  to=''
                  hasArrow={true}
                  menuPlacement='right-start'
                  menuTrigger={`{default:'click', lg: 'hover'}`}
                >
                  {permissions.includes('user_management') || role_id == 1 ? (
                    <MenuItem title='User Management' to={PATH.USER_LIST} hasBullet={true} />
                  ) : (
                    ''
                  )}
                  {permissions.includes('role') || role_id == 1 ? (
                    <MenuItem title='Role' to={PATH.ROLE} hasBullet={true} />
                  ) : (
                    ''
                  )}
                </MenuInnerWithSub>
              ) : (
                ''
              )}

              {permissions.includes('department') || role_id == 1 ? (
                <MenuItem
                  title='Departments'
                  icon='/media/icons/duotune/general/gen001.svg'
                  to={PATH.DEPT_LIST}
                />
              ) : (
                ''
              )}

              {permissions.includes('branch') || role_id == 1 ? (
                <MenuItem
                  title='Branch'
                  icon='/media/icons/duotune/coding/git-branch.svg'
                  to={PATH.BRANCH_LIST}
                />
              ) : (
                ''
              )}

              {supplier == true || role_id == 1 ? (
                <MenuInnerWithSub
                  title='Suppliers'
                  icon='/media/icons/duotune/communication/com014.svg'
                  to=''
                  hasArrow={true}
                  menuPlacement='right-start'
                  menuTrigger={`{default:'click', lg: 'hover'}`}
                >
                  {permissions.includes('supplier_group') || role_id == 1 ? (
                    <MenuItem title='Group' to={PATH.SG_LIST} hasBullet={true} />
                  ) : (
                    ''
                  )}
                  {permissions.includes('supplier') || role_id == 1 ? (
                    <MenuItem title='Supplier' to={PATH.SUP_LIST} hasBullet={true} />
                  ) : (
                    ''
                  )}
                </MenuInnerWithSub>
              ) : (
                ''
              )}

              {item == true || role_id == 1 ? (
                <MenuInnerWithSub
                  title='Items'
                  icon='/media/icons/duotune/coding/cod001.svg'
                  to=''
                  hasArrow={true}
                  menuPlacement='right-start'
                  menuTrigger={`{default:'click', lg: 'hover'}`}
                >
                  {permissions.includes('item_group') || role_id == 1 ? (
                    <MenuItem title='Group' to={PATH.IG_LIST} hasBullet={true} />
                  ) : (
                    ''
                  )}
                  {permissions.includes('item') || role_id == 1 ? (
                    <MenuItem title='Item' to={PATH.ITEM_LIST} hasBullet={true} />
                  ) : (
                    ''
                  )}
                </MenuInnerWithSub>
              ) : (
                ''
              )}

              {permissions.includes('unit') || role_id == 1 ? (
                <MenuItem
                  title='Units'
                  icon='/media/icons/duotune/ecommerce/ecm009.svg'
                  to={PATH.UNIT_LIST}
                />
              ) : (
                ''
              )}                                                          

              {permissions.includes('document_concerned') || role_id == 1 ? (
                <MenuItem
                  title='Document Concerned'
                  icon='/media/icons/duotune/files/fil012.svg'
                  to={PATH.DC_LIST}
                />
              ) : (
                ''
              )}
            </MenuInnerWithSub>
          ) : (
            ''
          )}

          {/* Logs */}
          {permissions.includes('log') || role_id == 1 ? <MenuItem title='Logs' to='/logs' /> : ''}
        </>
      ) : (
        <>
          <MenuInnerWithSub
            title='Quotation'
            to='/crafted'
            menuPlacement='bottom-start'
            menuTrigger='click'
          >
            <MenuItem
              title='Create'
              icon='/media/icons/duotune/files/fil005.svg'
              to={PATH.QS_CREATE}
            />
            <MenuItem
              title='Report List'
              icon='/media/icons/duotune/files/fil001.svg'
              to={PATH.QS_LIST}
            />
          </MenuInnerWithSub>
        </>
      )}
    </>
  )
}
