import axios from 'axios'
import {DepartmentModel} from '../models/DeptModel'

const API_URL = process.env.REACT_APP_API_URL

export const DEPT = `${API_URL}/department`
export const DEPT_FIND = `${API_URL}/department/find`
export const DEPT_OPTION = `${API_URL}/department-option`

export function DeptList(limit: number, skip: number, search: any) {
  return axios.get<DepartmentModel>(DEPT + `/${limit}&${skip}&${search || null}`)
}

export function DeptSave(
  dept_name: string,
  dept_no?: string,
  dept_alias_name?: string,
  header_dept_pr_no?: string,
  factory_no?: string,
  acc_remark?: string,
  groupleader_increase?: number,
  sectionhead_increase?: number,
  senior_increase?: number,
  depthead_increase?: number,
  active?: number
) {
  return axios.post(DEPT, {
    dept_name,
    dept_no,
    dept_alias_name,
    header_dept_pr_no,
    factory_no,
    acc_remark,
    groupleader_increase,
    sectionhead_increase,
    senior_increase,
    depthead_increase,
    active,
  })
}

export function DeptFind(id: string) {
  return axios.get<DepartmentModel>(DEPT_FIND + `/${id}`)
}

export function DeptUpdate(
  id: number,
  dept_name: string,
  dept_no?: string,
  dept_alias_name?: string,
  header_dept_pr_no?: string,
  factory_no?: string,
  acc_remark?: string,
  groupleader_increase?: number,
  sectionhead_increase?: number,
  senior_increase?: number,
  depthead_increase?: number,
  active?: number
) {
  return axios.put(DEPT, {
    id,
    dept_name,
    dept_no,
    dept_alias_name,
    header_dept_pr_no,
    factory_no,
    acc_remark,
    groupleader_increase,
    sectionhead_increase,
    senior_increase,
    depthead_increase,
    active,
  })
}

export function DeptDel(id: number) {
  return axios.delete(DEPT + `/${id}`)
}

export function DeptOption() {
  return axios.get(DEPT_OPTION)
}
