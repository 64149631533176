import {useState, useRef} from 'react'
import * as ReactCSV from 'react-csv'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFileExport} from '@fortawesome/free-solid-svg-icons'
import {ExportSUP} from '../../../../modules/api/requests/ExportDataRequest'
// import json2csv from 'json2csv'

const SupExportData = () => {
  const [data, setData] = useState<any>([])
  const csvLink: any = useRef()
  const name = 'suppliers'

  const headers = [
    {label: 'ID', key: 'index'},
    {label: 'Title', key: 'title'},
    {label: 'Tel', key: 'tel'},
    {label: 'Branch', key: 'branch_title'},
    {label: 'Supplier Group', key: 'supplier_group_title'},
    {label: 'Email', key: 'email'},
    {label: 'Fax', key: 'fax'},
    {label: 'Contact Name', key: 'contact_name'},
    {label: 'Address', key: 'address'},
    {label: 'Bpcs Code', key: 'bpcs_code'},
    {label: 'Active', key: 'active'},
    {label: 'Created By', key: 'created_by'},
    {label: 'Created At', key: 'created_at'},
    {label: 'Updated By', key: 'updated_by'},
    {label: 'Updated At', key: 'updated_at'},
  ]

  const getData = async () => {
    await ExportSUP()
      .then((res) => {
        let rawdata = res.data.data;
        const csvData = rawdata.map((row:any) => {
          return {
            index: `=""${row.index || '-'}""`,
            title: `=""${row.title || '-'}""`,
            tel: `=""${row.tel || '-'}""`,
            branch_title: `=""${row.branch_title || '-'}""`,
            supplier_group_title: `=""${row.supplier_group_title || '-'}""`,
            email: `=""${row.email || '-'}""`,
            fax: `=""${row.fax || '-'}""`,
            contact_name: `=""${row.contact_name || '-'}""`,
            address: `=""${row.address || '-'}""`,
            bpcs_code: `=""${row.bpcs_code || '-'}""`,
            active: `=""${row.active || 0}""`,
            created_by: `=""${row.created_by || '-'}""`,
            created_at: `=""${row.created_at || '-'}""`,
            updated_by: `=""${row.updated_by || '-'}""`,
            updated_at: `=""${row.updated_at || '-'}""`,
          }
        })
        setData(csvData)
        setTimeout(() => {
          csvLink.current.link.click()
        }, 200)
      })
      .catch((err) => {
        setData([])
      })
  }

  // const csvString = ReactCSV.stringify(data, {headers})
  // const csvString = json2csv.parse(data, { headers });

  return (
    <>
      <button
        className='btn btn-light-success'
        onClick={() => {
          getData()
        }}
      >
        <FontAwesomeIcon icon={faFileExport} className='fa-fw me-1' />
        Export
      </button>
      <ReactCSV.CSVLink
        data={data}
        filename={name || 'data.csv'}
        headers={headers}
        className='hidden'
        ref={csvLink}
        target='_blank'
      />
    </>
  )
}

export {SupExportData}
