import {flip} from '@popperjs/core'
import {UploadPIC, UploadDRAW, UploadQS} from '../../../api/requests/QuotationRequest'
import Swal from 'sweetalert2'

// --- use create quotation --- //

// export const UploadFile = (type: string, id: number, data: any) => {
//   var input = document.createElement('input')
//   input.type = 'file'
//   input.onchange = async (e: any) => {
//     var file = e.target.files[0]
//     Swal.fire({
//       title: 'Are you confirm to upload?',
//       icon: 'info',
//       showCancelButton: true,
//       confirmButtonColor: '#3085d6',
//       cancelButtonColor: '#d33',
//       confirmButtonText: 'Yes',
//       heightAuto: false,
//       returnFocus: false,
//     }).then(async (result) => {
//       if (result.isConfirmed) {
//         if (type == 'pic') {
//           uploadPIC(file, id, data)
//         } else if (type == 'draw') {
//           uploadDraw(file, id, data)
//         } else if (type == 'qs') {
//           uploadQS(file, id, data)
//         }
//       }
//     })
//   }
//   input.click()
// }

export const UploadFile = (type: string, id: number, data: any) => {
  let box = document.getElementById(`bg_load`) as HTMLInputElement
  let input = document.createElement('input')
  input.type = 'file'
  box.style.display = 'block'
  if(type == 'pic'){
    input.accept = 'image/*'
  }
  input.onchange = async (e: any) => {
    let file = e.target.files[0]
    let size: number = file.size || 0
    box.style.display = 'none'
    if (size <= 2000000) {
      Swal.fire({
        title: 'Are you confirm to upload?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        showLoaderOnConfirm: true,
        heightAuto: false,
        returnFocus: false,
        preConfirm: (e) => {
          if (type == 'pic') {
            return new Promise(async function (resolve) {
              if (file['type'].split('/')[0] === 'image') {
                let form = new FormData()
                form.append('file', file, file.name)
                await UploadPIC(form)
                  .then((res) => {
                    if (res.data.status == true) {
                      Swal.fire({
                        title: 'Successful upload!',
                        icon: 'success',
                        showConfirmButton: false,
                        heightAuto: false,
                        timer: 1500,
                        returnFocus: false,
                      })
                      data((data: any) =>
                        data.map((val: any) => {
                          if (val.id == id) {
                            val.file_url_pic = res.data.message
                          }
                          return val
                        })
                      )
                    }
                  })
                  .catch((err) => {
                    Swal.fire({
                      title: 'Failed to save',
                      icon: 'error',
                      heightAuto: false,
                      returnFocus: false,
                    })
                  })
              } else {
                Swal.fire({
                  title: 'Image file only',
                  text: 'Only image files are supported.',
                  icon: 'error',
                  heightAuto: false,
                  returnFocus: false,
                })
              }
            })
          } else if (type == 'draw') {
            return new Promise(async function (resolve) {
              let form = new FormData()
              form.append('file', file, file.name)
              await UploadDRAW(form)
                .then((res) => {
                  if (res.data.status == true) {
                    Swal.fire({
                      title: 'Successful upload!',
                      icon: 'success',
                      showConfirmButton: false,
                      heightAuto: false,
                      timer: 1500,
                      returnFocus: false,
                    })
                    data((data: any) =>
                      data.map((val: any) => {
                        if (val.id == id) {
                          val.file_url_draw = res.data.message
                        }
                        return val
                      })
                    )
                  }
                })
                .catch((err) => {
                  Swal.fire({
                    title: 'Failed to save',
                    icon: 'error',
                    heightAuto: false,
                    returnFocus: false,
                  })
                })
            })
          } else if (type == 'qs') {
            return new Promise(async function (resolve) {
              let form = new FormData()
              form.append('file', file, file.name)
              await UploadQS(form)
                .then((res) => {
                  if (res.data.status == true) {
                    Swal.fire({
                      title: 'Successful upload!',
                      icon: 'success',
                      showConfirmButton: false,
                      heightAuto: false,
                      timer: 1500,
                      returnFocus: false,
                    })
                    data((data: any) =>
                      data.map((val: any) => {
                        if (val.id == id) {
                          val.file_url_qto = res.data.message
                        }
                        return val
                      })
                    )
                  }
                })
                .catch((err) => {
                  Swal.fire({
                    title: 'Failed to save',
                    icon: 'error',
                    heightAuto: false,
                    returnFocus: false,
                  })
                })
            })
          }
        },
      })
    } else {
      Swal.fire({
        title: 'File size not more than 2 MB.',
        icon: 'error',
        heightAuto: false,
        returnFocus: false,
      })
    }
  }
  input.click()
}

export const uploadPIC = async (file: any, id: number, data: any) => {
  if (file['type'].split('/')[0] === 'image') {
    var form = new FormData()
    form.append('file', file, file.name)
    await UploadPIC(form)
      .then((res) => {
        if (res.data.status == true) {
          Swal.fire({
            title: 'Successful upload!',
            icon: 'success',
            showConfirmButton: false,
            heightAuto: false,
            timer: 1500,
            returnFocus: false,
          })
          data((data: any) =>
            data.map((val: any) => {
              if (val.id == id) {
                val.file_url_pic = res.data.message
              }
              return val
            })
          )
        }
      })
      .catch((err) => {
        Swal.fire({
          title: 'Failed to save',
          text: 'Please try again.',
          icon: 'error',
          heightAuto: false,
          returnFocus: false,
        })
      })
  } else {
    Swal.fire({
      title: 'Image file only',
      text: 'Only image files are supported.',
      icon: 'error',
      heightAuto: false,
      returnFocus: false,
    })
  }
}

export const uploadDraw = async (file: any, id: number, data: any) => {
  var form = new FormData()
  form.append('file', file, file.name)
  await UploadDRAW(form)
    .then((res) => {
      if (res.data.status == true) {
        Swal.fire({
          title: 'Successful upload!',
          icon: 'success',
          showConfirmButton: false,
          heightAuto: false,
          timer: 1500,
          returnFocus: false,
        })
        data((data: any) =>
          data.map((val: any) => {
            if (val.id == id) {
              val.file_url_draw = res.data.message
            }
            return val
          })
        )
      }
    })
    .catch((err) => {
      Swal.fire({
        title: 'Failed to save',
        text: 'Please try again.',
        icon: 'error',
        heightAuto: false,
        returnFocus: false,
      })
    })
}

export const uploadQS = async (file: any, id: number, data: any) => {
  var form = new FormData()
  form.append('file', file, file.name)
  await UploadQS(form)
    .then((res) => {
      if (res.data.status == true) {
        Swal.fire({
          title: 'Successful upload!',
          icon: 'success',
          showConfirmButton: false,
          heightAuto: false,
          timer: 1500,
          returnFocus: false,
        })
        data((data: any) =>
          data.map((val: any) => {
            if (val.id == id) {
              val.file_url_qto = res.data.message
            }
            return val
          })
        )
      }
    })
    .catch((err) => {
      Swal.fire({
        title: 'Failed to save',
        text: 'Please try again.',
        icon: 'error',
        heightAuto: false,
        returnFocus: false,
      })
    })
}
